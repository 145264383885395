import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import { Form, TextInput, Card, Button } from "library";
import { post, parseErrorResponse, save_token, isLoggedIn } from "functions";

// Google OAuth documentation: https://developers.google.com/identity/oauth2/web/guides/use-token-model
const GOOGLE_CLIENT_ID =
    "297720701797-4r7ijvdjgu5e7tf23jto6g4dqm8k23cs.apps.googleusercontent.com";

export default function Login() {
    const navigate = useNavigate();
    const [client, setClient] = useState();
    const [loading, setLoading] = useState(false);

    const showErrorModal = (text) => {
        setLoading(false);
        Swal.fire({
            title: "Warning",
            text,
            icon: "error",
            confirmButtonText: "OK",
        });
    };

    const loginCallback = useCallback(
        (data) => {
            // Sample errors:
            // {"error":"Unauthorized","code":401,"data":{"message":"No active account found with the given credentials","code":"no_active_account"}}
            // {"error":"Unauthorized","code":401,"data":{"detail":"Token is invalid or expired","code":"token_not_valid"}}
            // {"error":"Bad Request","code":400,"data":{"error":"All Seats Taken"}}
            // {"error":"Internal Server Error","code":500,"data":null}
            if (data.error) {
                const error =
                    (data.data &&
                        (data.data.message || data.data.error || data.data.detail)) ||
                    data.error;
                showErrorModal(error);
                return;
            }
            save_token(data);
            navigate("/home");
        },
        [navigate],
    );

    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/home");
            return;
        }

        const googleLogin = async (state) => {
            if (state && state.access_token) {
                try {
                    const data = await post("/user/google_login/", state);
                    loginCallback(data);
                } catch (xhr) {
                    showErrorModal(parseErrorResponse(xhr));
                }
            }
        };

        const googleError = (state) => {
            console.log("Google Error", state);
        };

        const client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            callback: (response) => googleLogin(response),
            error_callback: (response) => googleError(response),
        });

        setClient(client);
    }, [navigate, loginCallback]);

    const login = async (state) => {
        let error = "";

        // Validate for before submitting
        if (!state["email"]) {
            error = "Email field is missing";
        } else if (!state["password"]) {
            error = "Password field is missing";
        }

        if (error) {
            showErrorModal(error);
            return;
        }

        setLoading(true);

        try {
            const data = await post("/user/token/", {
                email: state["email"],
                password: state["password"],
            });
            loginCallback(data);
        } catch (xhr) {
            showErrorModal(parseErrorResponse(xhr));
        }
    };

    return (
        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <Card>
                <div className="card-header text-center pt-4">
                    <h5>Sign in</h5>
                </div>
                <div className="card-body text-end">
                    <Form
                        submit={login}
                        submit_text="Login"
                        submit_button_type="gradient-info"
                        submit_button_class={"w-100 my-4 mb-4"}
                        waiting_for_external_response={loading}
                    >
                        <TextInput key="email" name="email" placeholder="Email" />
                        <TextInput
                            key="password"
                            type="password"
                            name="password"
                            placeholder="Password"
                        />
                        <Link className="btn btn-link m-0 p-0" to="/reset-password">
                            Forgot password?
                        </Link>
                    </Form>
                    {client && (
                        <Button
                            onClick={() => client.requestAccessToken()}
                            style={{ width: "100%" }}
                        >
                            <div
                                style={{
                                    marginRight: "10px",
                                    display: "inline-block",
                                    lineHeight: "20px",
                                }}
                            >
                                Login with Google
                            </div>
                            <img
                                style={{
                                    width: "20px",
                                    display: "inline-block",
                                    verticalAlign: "top",
                                }}
                                src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                                alt=""
                            />
                        </Button>
                    )}
                </div>
            </Card>
        </div>
    );
}
