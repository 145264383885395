import { useMap } from "react-map-gl";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";

export function ZoomControls() {
    const { current: map } = useMap();

    return (
        <div className="lui-flex lui-overflow-hidden lui-justify-center lui-items-center  lui-shadow-sm lui-rounded-3xl  lui-flex-col lui-bg-white">
            <Tooltip
                trigger={
                    <Button
                        fullWidth
                        variant="base"
                        className="lui-border lui-border-solid lui-border-gray-300 lui-pt-4 lui-pb-3 lui-px-3 hover:lui-bg-gray-300 "
                        onClick={() => {
                            map.zoomIn();
                        }}
                    >
                        <Icon name="Plus" color="inherit" />
                    </Button>
                }
                content="Zoom In"
                placement="left"
            />

            <Tooltip
                trigger={
                    <Button
                        fullWidth
                        variant="base"
                        className="lui-border lui-border-solid lui-border-gray-300 lui-pt-4 lui-pb-3 lui-px-3 hover:lui-bg-gray-300 "
                        onClick={() => {
                            map.zoomOut();
                        }}
                    >
                        <Icon name="Minus" color="inherit" />
                    </Button>
                }
                content="Zoom Out"
                placement="left"
            />
        </div>
    );
}
