import { useCallback, useContext, useEffect } from "react";

import { type Feature } from "geojson";
import { useMap } from "react-map-gl";
import { MapFilter } from "@src/components/map_filter/map_filter";
import { QuickSearch } from "@src/land_ui/quick_search/quick_search";
import { Typography } from "@src/land_ui/typography/typography";
import { ParcelViewerContext } from "./context";
import { findFeatureOnMap, findParcelOnMap } from "./controls/search";
import { MapMenu } from "./map_menu/menu";
import { type FIPSLookupResult, type Point, type SearchResult } from "./types";
import { Button } from "@src/land_ui/button/button";
import { useParcel } from "@src/hooks/useParcel";
import { Sidebar } from "@src/land_ui/sidebar/sidebar";
import { ExportListMenu } from "./map_menu/export_list_menu";
import { useQueryParam } from "@src/hooks/useQueryParam";
import { fetch } from "@src/functions";
import { useSidebarControl } from "@src/hooks/useSidebarControl";
import { OwnerPropertySidebar } from "./map_menu/owner_property_sidebar";

interface MapHeaderProps {
    setSearchResult: (result: SearchResult) => void;
    setShowFilterPanel: (showFilterPanel: boolean) => void;
}

export function MapHeader({ setSearchResult }: MapHeaderProps) {
    const [parcelExport, , removeParcelExport] = useQueryParam("parcelExport", null);
    const { setMapFilter, county, setCounty, setSavedList } =
        useContext(ParcelViewerContext);
    const { current: map } = useMap();
    const { isSidebarOpen, setIsSidebarOpen, sidebarType } = useSidebarControl();
    const { removeParcelQuery, setParcelQuery } = useParcel();
    // Locate parcel on map and mark it active
    const highlightParcel = useCallback(
        async (point: Point, propertyId?: number) => {
            setMapFilter(null);

            const result = await findParcelOnMap(map, point);

            if (result && result.mapFeature) {
                const { mapFeature } = result;
                const parcelID = propertyId || parseInt(`${mapFeature.id}`, 10);
                setParcelQuery(parcelID);
            }
        },
        [map, setMapFilter, setParcelQuery],
    );

    // Locate feature on map and highlight it by applying shadow filter
    const highlightFeature = useCallback(
        async (feature: Feature) => {
            // Reset filters
            setMapFilter(null);
            setCounty(null);

            const result = await findFeatureOnMap(map, feature);

            if (result && result.mapFeature) {
                const { identifyLayer, filter, idField, mapFeature } = result;

                // Filter layer by feature ID
                let newFilter = ["==", idField, mapFeature.id];
                let inverseFilter = ["!", newFilter];

                // Include County/Zip layer filter
                if (filter) {
                    newFilter = ["all", filter, newFilter];
                    inverseFilter = ["all", filter, inverseFilter];
                }

                // Apply map filter for shadow layer
                setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                // Mark county as "active" for Parcel # search
                const featureType = feature?.properties?.feature_type;
                if (featureType === "district") {
                    setCounty({
                        // id is the county FIPS code
                        id: mapFeature.properties.fips,
                        name: feature.properties.name,
                    });
                }
            }
        },
        [map, setMapFilter, setCounty],
    );

    const [activeCounty, , removeActiveCounty] = useQueryParam<string>("county", null);
    useEffect(() => {
        if (activeCounty) {
            (async () => {
                const result: FIPSLookupResult = await fetch(
                    `/api/property/fips_lookup/?name=${activeCounty}`,
                );
                if (result?.results.length > 0) {
                    const newCounty = result.results[0];
                    const savedList = {
                        id: "temp-search-filter",
                        title: "temp-search-filter",
                        search_filters: {
                            county: newCounty.id,
                            countyOption: {
                                id: newCounty.id,
                                label: newCounty.label,
                                county: newCounty.label,
                                state: newCounty.state,
                            },
                        },
                    };
                    setSavedList(savedList);
                    setIsSidebarOpen(true, "filter");
                    removeActiveCounty();
                }
            })();
        }
    }, [activeCounty, removeActiveCounty, setIsSidebarOpen, setSavedList]);

    return (
        <div className="lui-absolute  lui-z-20 lui-top-5 lui-left-6 lui-flex lui-gap-3 lui-items-center">
            <MapMenu />
            <Button
                variant="primary"
                icon="Filter"
                onClick={() => {
                    setIsSidebarOpen(true, "filter");
                    removeParcelQuery();
                }}
            >
                Filter Parcels
            </Button>

            {/* Ensure the filter resets only when switching from a different sidebar, not when toggling the same sidebar */}
            {sidebarType === "filter" && (
                <MapFilter
                    isOpen={isSidebarOpen("filter")}
                    setIsOpen={(isOpen) => {
                        setIsSidebarOpen(isOpen, "filter");
                    }}
                />
            )}

            <OwnerPropertySidebar />

            <Typography color="white" weight="bold" size="lg">
                or
            </Typography>
            <QuickSearch
                county={county}
                onSearch={(search) => {
                    if (search.PropertyID) {
                        return highlightParcel(search.point, search.PropertyID);
                    }

                    if (search.point) {
                        return highlightParcel(search.point);
                    }

                    if (search.ownerResults) {
                        return setSearchResult(search.ownerResults);
                    }

                    if (search.feature) {
                        return highlightFeature(search.feature);
                    }
                }}
            />

            {parcelExport && (
                <Sidebar
                    isOpen={Boolean(parcelExport)}
                    setIsOpen={(isOpen) => {
                        if (!isOpen) {
                            removeParcelExport();
                        }
                    }}
                >
                    <ExportListMenu exportId={parcelExport} />
                </Sidebar>
            )}
        </div>
    );
}
