import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-material.css"; // Theme

// https://ag-grid.com/react-data-grid/getting-started/

export default class AGGrid extends Component {
    constructor(props) {
        super(props);

        this.gridRef = React.createRef();

        this.applyFilters = this.applyFilters.bind(this);
        this.handle_filter_change = this.handle_filter_change.bind(this);
        this.handle_column_moved = this.handle_column_moved.bind(this);
        this.get_row_id = this.get_row_id.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.filters != prevProps.filters) {
            this.applyFilters(this.props.filters);
        }
    }

    applyFilters(filters) {
        const gridApi = this.gridRef.current?.api;

        const incoming_filters = { ...filters };

        // Reset sorting on all columns
        gridApi.applyColumnState({
            defaultState: {
                sort: null,
            },
        });

        const sorting =
            incoming_filters && incoming_filters["sorting"]
                ? incoming_filters["sorting"]
                : null;
        if (sorting) {
            delete incoming_filters["sorting"];
            gridApi.applyColumnState({
                state: sorting,
            });
        }

        const order =
            incoming_filters && incoming_filters["order"]
                ? incoming_filters["order"]
                : null;
        if (order) {
            delete incoming_filters["order"];
            gridApi.applyColumnState({
                state: order,
                applyOrder: true,
            });
        }

        if (incoming_filters) {
            gridApi.setFilterModel(incoming_filters);
        }
    }

    handle_filter_change(e) {
        // Ignore events emitted by calling gridApi methods directly to avoid
        // infinite loops
        if (e.source === "api") {
            return;
        }

        const current_filters = {
            ...this.gridRef.current?.api.getFilterModel(),
        };

        const gridApi = e.api;
        const current_sort = gridApi.getColumnState().find((s) => s.sort != null);

        const sorting = [];
        if (current_sort) {
            sorting.push({
                colId: current_sort["colId"],
                sort: current_sort["sort"],
            });
        }

        const order = gridApi.getColumnState().map((col) => {
            return { colId: col.colId };
        });

        current_filters["sorting"] = sorting;
        current_filters["order"] = order;

        if (this.props.handle_filter_change) {
            this.props.handle_filter_change(current_filters);
        }
    }

    handle_column_moved(e) {
        if (e.finished && e.source === "uiColumnMoved") {
            this.handle_filter_change(e);
        }
    }

    get_row_id(context) {
        if (context["data"]["id"]) {
            return context["data"]["id"];
        }

        return null;
    }

    render() {
        const rows = this.props.rows;
        const columns = this.props.columns;

        const gridOptions = {
            autoSizeStrategy: {
                type: this.props.fill_table ? "fitGridWidth" : "fitCellContents",
            },
            enableCellTextSelection: true,
        };

        const container_style = {
            height: this.props.height ? this.props.height : "500px",
        };
        const auto_height = {};
        if (this.props.height && this.props.height == "auto") {
            auto_height["domLayout"] = "autoHeight";
        }

        return (
            // Container
            <div className="ag-theme-material" style={container_style}>
                {/* The AG Grid component */}
                <AgGridReact
                    ref={this.gridRef}
                    gridOptions={gridOptions}
                    pagination={false}
                    rowData={rows}
                    columnDefs={columns}
                    getRowId={this.props.getRowId || this.get_row_id}
                    onFilterChanged={this.handle_filter_change}
                    onColumnMoved={this.handle_column_moved}
                    onSortChanged={this.handle_filter_change}
                    onCellClicked={this.props.onCellClicked}
                    selectionChanged={this.props.selectionChanged}
                    getRowStyle={this.props.getRowStyle}
                    {...auto_height}
                />

                {/*<Button type="info" onClick={this.get_filters}>
                    Save Filters
                </Button>*/}
            </div>
        );
    }
}
