import React, { Component } from "react";

import { toast } from "react-toastify";

import {
    get_all_children,
    get_children,
    is_valid_react_child,
    prettify_string,
} from "functions";

import { ajax_wrapper } from "functions";
import { Button, DeleteButton, Loading } from "library";

class FormWithChildren extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form_child_update_key: null,
            required: [],

            defaults: {},

            form_is_saving_right_now: false,
            show_delete_modal: false,
        };

        this.get_form_defaults = this.get_form_defaults.bind(this);

        this.handle_change_event = this.handle_change_event.bind(this);
        this.set_form_state = this.set_form_state.bind(this);

        this.reset_state_on_submit = this.reset_state_on_submit.bind(this);

        this.form_submit = this.form_submit.bind(this);
        this.form_submit_callback = this.form_submit_callback.bind(this);
        this.form_submit_failure = this.form_submit_failure.bind(this);
        this.delete_callback = this.delete_callback.bind(this);

        this.handle_key_press = this.handle_key_press.bind(this);
    }

    componentDidMount() {
        const defaults = this.get_form_defaults();
        this.setState(defaults, this.set_global_state);
    }

    get_form_defaults(clean) {
        const defaults = JSON.parse(JSON.stringify(this.props.defaults || {}));
        const children = get_children(this.props);

        Object.keys(children).forEach((index) => {
            const child = children[index];
            if (child) {
                if (child.props && "default" in child.props) {
                    defaults[child.props.name] = child.props.default;
                } else if (clean) {
                    defaults[child.props.name] = undefined;
                }
            }
        });

        if (!("required" in defaults)) {
            defaults.required = "";
        }

        return defaults;
    }

    handle_change_event(e) {
        const newState = {};

        const name = e.target.getAttribute("name");
        newState[name] = e.target.value;

        this.set_form_state(newState);
    }

    set_form_state(state) {
        // Form validation here

        this.setState(state);

        if (this.props.bubble_state) {
            this.props.bubble_state(state);
        }
    }

    reset_state_on_submit() {
        const defaults = this.get_form_defaults(true);
        defaults.form_is_saving_right_now = false;

        // Reset key values for all children in order to fully clear states and rerender
        const date = Date.now();
        defaults.form_child_update_key = date;

        this.setState(defaults);
    }

    form_submit() {
        // This is not great as it captures ALL form state and causes invalid fields to be submitted
        const data = { ...this.state };
        delete data.children;
        delete data.form_state;
        delete data.defaults;
        delete data.form_child_update_key;
        delete data.form_is_saving_right_now;
        delete data.required;
        delete data.show_delete_modal;

        const new_state = {
            required: [],
        };

        const children = get_children(this.props);
        const required = this.check_required_children([], children);

        if (required.length > 0) {
            new_state["required"] = required;
            this.setState(
                new_state,
                function () {
                    for (const item of this.state.required) {
                        toast.error(item);
                    }
                }.bind(this),
            );
        } else {
            for (const item in data) {
                if (item.endsWith("[]")) {
                    data[item] = JSON.stringify(data[item]);
                }
            }

            if (this.props.submit) {
                this.props.submit(
                    data,
                    this.form_submit_callback,
                    this.form_submit_failure,
                );
            } else if (this.props.submit_url) {
                ajax_wrapper(
                    "POST",
                    this.props.submit_url,
                    data,
                    this.form_submit_callback,
                    this.form_submit_failure,
                );

                new_state.form_is_saving_right_now = true;
            }

            this.setState(new_state);
        }
    }

    form_submit_callback(value) {
        // Handle standard form submission
        if (this.props.submit_success) {
            this.props.submit_success(value);
        }

        if (this.props.reset_state_on_submit) {
            this.reset_state_on_submit();
        } else {
            this.setState({ form_is_saving_right_now: false });
        }

        toast.success(this.props.toast_text ? this.props.toast_text : "Save Complete!");
    }

    form_submit_failure(value) {
        if (this.props.submit_failure) {
            this.props.submit_failure(value);
        }

        this.setState({ form_is_saving_right_now: false });
    }

    delete_callback() {
        this.setState(
            {
                show_delete_modal: false,
            },
            function () {
                if (this.props.delete_callback) {
                    this.props.delete_callback();
                }
            }.bind(this),
        );
    }

    check_required_children(required, context) {
        Object.keys(context).forEach((index) => {
            const child = context[index];
            if (is_valid_react_child(child)) {
                const { props } = child;

                if (props.required === true) {
                    if (
                        !(props.name in this.state) ||
                        this.state[props.name] === undefined ||
                        this.state[props.name] === ""
                    ) {
                        let field_name = props.label;
                        // Fallback behavior in case no label was applied to the input
                        if (
                            !field_name ||
                            field_name === "" ||
                            typeof field_name !== "string" ||
                            !(field_name instanceof String)
                        ) {
                            field_name = props.name;
                        }

                        required.push(
                            `The field ${prettify_string(
                                field_name,
                            )} must be filled out to submit the form. `,
                        );
                    }
                }

                let { children } = child.props;
                if (typeof children !== "undefined") {
                    if (typeof children.length === "undefined") {
                        children = [child.props.children];
                    }
                    required = this.check_required_children(required, children);
                }
            }
        });

        return required;
    }

    handle_key_press(event) {
        if (this.props.submit_on_enter !== false) {
            if (event.key === "Enter") {
                this.form_submit();
            }
        }
    }

    render() {
        const layout = `form ${this.props.className}`;
        const style = this.props.style ? this.props.style : {};

        const newProps = {
            set_form_state: this.set_form_state,
            handle_change: this.handle_change_event,
            handle_key_press: this.handle_key_press,
        };

        let components = get_all_children(this, newProps, this.state, true);

        if (this.state.form_child_update_key) {
            const new_components = [];
            Object.keys(components).forEach((i) => {
                let component = components[i];
                component = React.cloneElement(component, {
                    key: `${this.state.form_child_update_key}_${i}`,
                });
                new_components.push(component);
            });

            components = new_components;
        }

        let submit_button = null;
        let float;

        // Display loading spinner based on `form_is_saving_right_now` for
        // forms using `props.submit_url`. Use `waiting_for_external_response`
        // for forms using `props.submit`.
        const loading = this.props.submit_url
            ? this.state.form_is_saving_right_now
            : Boolean(this.props.waiting_for_external_response);

        if (this.props.submit_url || this.props.submit) {
            const submit_button_type =
                this.props.submit_button_type || "gradient-success";

            float = this.props.submit_button_float
                ? this.props.submit_button_float
                : { float: "left" };

            let default_submit_text = "Save";
            // Anti-mash behavior for form.  This will force users to wait until callback functions have completed
            // and ensure the form is submitted properly
            let submit_disabled = {};
            if (loading) {
                submit_disabled = { disabled: "disabled" };
                default_submit_text = "Saving";
            }

            submit_button = (
                <Button
                    key={"form_submit_button_key"}
                    style={float}
                    onClick={this.form_submit}
                    type={submit_button_type}
                    className={this.props.submit_button_class || ""}
                    {...submit_disabled}
                >
                    {this.props.submit_text || default_submit_text}
                </Button>
            );
        }

        const failed = [];
        /*
        if (this.state.required != []) {
            Object.keys(this.state.required).forEach((i) => {
                failed.push(
                    <Alert type="danger" text={this.state.required[i]} />,
                );
            });
        }
        */

        let delete_button = null;
        if (this.props.delete_url) {
            delete_button = (
                <div style={{ textAlign: "left" }}>
                    <DeleteButton
                        style={{ float: "left" }}
                        url={this.props.delete_url}
                        text={
                            this.props.delete_text ? this.props.delete_text : "Delete"
                        }
                        inline={true}
                        inline_activate={() =>
                            this.setState({ show_delete_modal: true })
                        }
                        inline_deactivate={() =>
                            this.setState({ show_delete_modal: false })
                        }
                        callback={this.delete_callback}
                    />
                </div>
            );
        }

        // When delete modal is active, hide the form
        if (this.state.show_delete_modal) {
            components = null;
            submit_button = null;
        }

        return (
            <div className={layout} style={style} onKeyPress={this.handle_key_press}>
                <Loading loaded={!loading} cover={true}>
                    {components}
                    {failed}
                    {delete_button}
                    {submit_button}
                    <div style={{ clear: "both" }} />
                </Loading>
            </div>
        );
    }
}

export default FormWithChildren;
