import { useQueryClient } from "@tanstack/react-query";
import { isNull, omitBy } from "lodash";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@src/land_ui/button/button";
import { Input } from "@src/land_ui/input/input";
import { Modal } from "@src/land_ui/modal/modal";
import { useSavedlistsCreate } from "@src/orval/gen/api";
import { type ParcelsListParams } from "@src/orval/gen/model";
import { type CountyOption } from "@src/pages/parcel_viewer/types";
import { ErrorMessageBox } from "@src/components/ErrorMessageBox";
import { type FeaturePolygon } from "@src/components/map_tools/map_polygon";

// This is the type used when saving a filter to the db
export interface SaveFilterParcelsListParams extends ParcelsListParams {
    countyOption: CountyOption;
    polygons?: FeaturePolygon[];
}

interface SaveFilterModalProps {
    saveFilter: SaveFilterParcelsListParams;
    initialFilterName?: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    trigger?: React.ReactElement;
}
export function SaveFilterModal({
    isOpen,
    setIsOpen,
    saveFilter,
    initialFilterName = "",
    trigger,
}: SaveFilterModalProps) {
    const queryClient = useQueryClient();
    const { mutate, isPending, error } = useSavedlistsCreate({
        mutation: {
            onSuccess: () => {
                setIsOpen(false);
                queryClient.invalidateQueries({
                    queryKey: ["savedFilterList"],
                });
            },
        },
    });
    const schema = z.object({
        name: z.string().min(1),
    });
    type FormValues = z.infer<typeof schema>;
    const { register, handleSubmit } = useForm<FormValues>({
        defaultValues: {
            name: initialFilterName,
        },
    });

    const onSubmit = handleSubmit((formValues) => {
        setIsOpen(false);
        mutate({
            data: {
                search_filters: omitBy(saveFilter, isNull),
                title: formValues.name,
            },
        });
    });

    return (
        <Modal trigger={trigger} isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={onSubmit}>
                <Modal.Header preventAutoFocus>Create Filter</Modal.Header>
                <Modal.Content className="lui-p-6">
                    <Input
                        label="Name"
                        placeholder="Enter filter name"
                        autoFocus
                        autoSelectionOnFocus
                        {...register("name")}
                    />

                    <ErrorMessageBox isShown={Boolean(error)}>
                        Something went wrong
                    </ErrorMessageBox>
                </Modal.Content>
                <Modal.Footer className="lui-flex lui-gap-3 lui-justify-end">
                    <Button variant="base" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button isLoading={isPending} variant="primary" type="submit">
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
