function get_filtered_region_data(incoming_rows, filters, acrage_key) {
    const outgoing_rows = [];
    for (const row of incoming_rows) {
        let valid = true;

        for (const key in filters) {
            if (["order", "sorting", "map_filter_data"].includes(key)) {
                continue;
            } else if (
                [
                    "state",
                    "name",
                    "county_name",
                    "out_of_county",
                    "out_of_state",
                ].includes(key)
            ) {
                valid = compare_row_to_conditions(row[key], filters[key]);
            } else {
                let acrage_value = 0;
                if (row[acrage_key] && row[acrage_key][key]) {
                    acrage_value = row[acrage_key][key];
                }

                valid = compare_row_to_conditions(acrage_value, filters[key]);
            }

            if (!valid) {
                break;
            }
        }

        if (valid) {
            outgoing_rows.push(row);
        }
    }

    return outgoing_rows;
}

function compare_row_to_conditions(value, filter) {
    let valid = true;
    if ("conditions" in filter) {
        const operator = filter["operator"];
        const valid_1 = compare_row_to_condition(value, filter["condition1"]);
        const valid_2 = compare_row_to_condition(value, filter["condition2"]);

        if (operator == "OR") {
            valid = valid_1 || valid_2;
        } else {
            valid = valid_1 && valid_2;
        }
    } else {
        valid = compare_row_to_condition(value, filter);
    }

    return valid;
}

function compare_row_to_condition(value, filter) {
    const type = filter["type"];
    let comparator = filter["filter"];

    const string_filters = [
        "contains",
        "notContains",
        "equals",
        "notEqual",
        "startsWith",
        "endsWith",
    ];
    // Cast values as lower case strings to ensure clean comparisions
    if (string_filters.includes(type)) {
        value = String(value).toLowerCase();
        comparator = String(comparator).toLowerCase();
    }

    if (type == "contains") {
        return value.includes(comparator);
    } else if (type == "notContains") {
        return !value.includes(comparator);
    } else if (type == "equals") {
        return value == comparator;
    } else if (type == "notEqual") {
        return value != comparator;
    } else if (type == "startsWith") {
        return value.startsWith(comparator);
    } else if (type == "endsWith") {
        return value.endsWith(comparator);
    } else if (type == "blank") {
        return value == 0 || value == "";
    } else if (type == "notBlank") {
        return !(value == 0 || value == "");
    } else if (type == "greaterThan") {
        return value > comparator;
    } else if (type == "greaterThanOrEqual") {
        return value >= comparator;
    } else if (type == "lessThan") {
        return value < comparator;
    } else if (type == "lessThanOrEqual") {
        return value <= comparator;
    } else if (type == "inRange") {
        const comparator_2 = filter["filterTo"];
        return value > comparator && value < comparator_2;
    }
}

export default get_filtered_region_data;
