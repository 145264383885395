import { useSessionStorage } from "usehooks-ts";

type SidebarType = "menu" | "filter" | "owner";
interface SidebarControlState {
    isOpen: boolean;
    type: SidebarType;
}
export function useSidebarControl() {
    const [sidebarType, setSidebarType, removeSidebarType] =
        useSessionStorage<SidebarControlState>("sidebar", null);

    function isSidebarOpen(type: SidebarType) {
        return Boolean(sidebarType?.isOpen && sidebarType.type === type);
    }

    function setIsSidebarOpen(isOpen: boolean, type: SidebarType = sidebarType?.type) {
        if (!isOpen) {
            removeSidebarType();
        } else {
            setSidebarType({ isOpen, type });
        }
    }

    function hideSidebar() {
        const type = sidebarType?.type;
        if (type) {
            setSidebarType({ isOpen: false, type });
        }
    }

    return {
        isSidebarOpen,
        setIsSidebarOpen,
        hideSidebar,
        sidebarType: sidebarType?.type,
    };
}
