import { type AllGeoJSON, bbox } from "@turf/turf";
import { useMap } from "react-map-gl";
import { useParcelsRetrieve } from "@src/orval/gen/api";
import { useQueryParam } from "./useQueryParam";
import { useSidebarControl } from "./useSidebarControl";

interface useParcelProps {
    parcelId?: number;
    enabled?: boolean;
}
/**
 * Specific hook with utilities for parcels controls and navigation
 */
export function useParcel(props?: useParcelProps) {
    const { hideSidebar } = useSidebarControl();
    const {
        value,
        setValue: setParcelParamValue,
        removeValue,
    } = useQueryParam<number>("parcel", null);

    const {
        refetch,
        isLoading,
        data: parcel,
        error,
    } = useParcelsRetrieve(props?.parcelId ?? value, {
        query: {
            enabled: Boolean(props?.enabled) && Boolean(props?.parcelId),
            queryKey: ["parcelsRetrieve", props?.parcelId],
        },
    });
    const mapRef = useMap();

    function setValue(v: number) {
        setParcelParamValue(v);
        hideSidebar();
    }

    async function goToParcelInMap({
        showParcelDetailPanel,
    }: {
        showParcelDetailPanel: boolean;
    }) {
        const parcelData = await refetch();

        if (showParcelDetailPanel) {
            setValue(props?.parcelId);
        }

        if (!parcelData?.data?.shape) {
            return;
        }

        const bounds = bbox(parcelData.data.shape as unknown as AllGeoJSON);
        if (bounds) {
            mapRef.current.fitBounds(
                [
                    [bounds[0], bounds[1]],
                    [bounds[2], bounds[3]],
                ],
                {
                    padding: 200,
                    duration: 0,
                },
            );
        }
    }

    return {
        parcel,
        goToParcelInMap,
        removeParcelQuery: removeValue,
        setParcelQuery: setValue,
        parcelQueryId: value,
        isSelectedParcel: value === props?.parcelId,
        isLoading,
        error,
    };
}
