import { useEffect } from "react";
import MapboxPopup, { type MapboxPopupProps } from "@src/components/mapbox_popup";
import { useParcel } from "@src/hooks/useParcel";
import { Button } from "@src/land_ui/button/button";
import { Typography } from "@src/land_ui/typography/typography";
import { formatCurrency, PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import { isNumber } from "lodash";

interface CompPopupProperties extends MapboxPopupProps {
    title: string;
    properties: any;
    onClose?: () => void;
}

export default function CompPopup({
    title,
    properties,
    onClose,
    longitude,
    latitude,
}: CompPopupProperties) {
    const { parcelQueryId, removeParcelQuery } = useParcel();
    useEffect(() => {
        // Hide the parcel modal when the mapbox popup is closed
        if (parcelQueryId) {
            removeParcelQuery();
        }
    }, [parcelQueryId, removeParcelQuery]);

    const daysInMarket =
        isNumber(properties.days_on_market) && properties.days_on_market > 0
            ? `${properties.days_on_market}+`
            : PLACEHOLDER_LABEL;

    return (
        <MapboxPopup
            key={properties.id}
            longitude={longitude}
            latitude={latitude}
            onClose={onClose}
            title={title}
            offset={17}
            columns={2}
            footer={
                <Button href={properties.url} openInNewTab={true} fullWidth>
                    View parcel
                </Button>
            }
        >
            <RowDetail title="Lot size" value={`${properties.lot_size} acres`} />
            <RowDetail title="Price" value={formatCurrency(properties.price)} />
            <RowDetail title="PPA" value={formatCurrency(properties.ppa)} />
            <RowDetail title="Status" value={properties.status} />
            <RowDetail title="Subdivision" value={properties.subdivision || "N/A"} />
            {properties.days_on_market > 0 && (
                <RowDetail title="Days on market" value={daysInMarket} />
            )}
        </MapboxPopup>
    );
}

function RowDetail({ title, value }: { title: string; value: string }) {
    return (
        <>
            <Typography size="sm">{title}</Typography>
            <Typography size="sm" weight="medium">
                {value}
            </Typography>
        </>
    );
}
