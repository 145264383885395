import { type ReactNode } from "react";
import { useIntersectionObserver } from "usehooks-ts";

interface InViewProps {
    id?: string;
    children?: ReactNode;
    style?: Record<string, any>;
    className?: string;
    onEnter?: () => void;
    onLeave?: () => void;
}

export function InView({
    id,
    onEnter,
    onLeave,
    children,
    style = {},
    className = "",
}: InViewProps) {
    const { isIntersecting, ref } = useIntersectionObserver({
        onChange: (entry) => {
            if (entry) {
                onEnter && onEnter();
            } else {
                onLeave && onLeave();
            }
        },
        threshold: 0.1,
    });

    return (
        <div id={id} ref={ref} style={style} className={className}>
            {isIntersecting && children}
        </div>
    );
}
