import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
    LAND_SCRUB_COMPLETED,
    LAND_SCRUB_FAILURE,
    LAND_SCRUB_WORKING,
} from "constants";
import { AGGrid } from "components";
import { fetch, parseErrorResponse, formatCredits, formatDate } from "functions";

function Progress({ data }) {
    const { percent_completed } = data;
    const status = getDisplayStatus(data);
    const color = status === LAND_SCRUB_COMPLETED ? "success" : "info";
    return (
        <div>
            <span className="me-2 text-xs">{percent_completed}%</span>
            <div style={{ display: "inline-block" }}>
                <div
                    className="progress"
                    style={{ height: "3px", width: "120px", margin: "0" }}
                >
                    <div
                        className={`progress-bar bg-gradient-${color}`}
                        role="progressbar"
                        aria-valuenow={percent_completed}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${percent_completed}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

function Status({ data }) {
    const status = getDisplayStatus(data);
    const color =
        status === LAND_SCRUB_COMPLETED
            ? "success"
            : status === LAND_SCRUB_FAILURE
              ? "danger"
              : "info";

    let status_icon = <i className={`bg-${color}`} />;

    if (status === LAND_SCRUB_WORKING) {
        status_icon = (
            <div
                className="spinner-border text-info"
                role="status"
                style={{
                    fontSize: "6px",
                    width: ".6rem",
                    height: ".6rem",
                    marginRight: "0.375rem",
                }}
            ></div>
        );
    }

    return (
        <span className="badge badge-dot me-4">
            {status_icon}
            <span className="text-dark text-xs">{status}</span>
        </span>
    );
}

function getDisplayStatus({ status, percent_completed }) {
    // Consider jobs with 100% progress completed even if the actual status is
    // LAND_SCRUB_FAILURE due to 1 or more rows failing.
    if (percent_completed === 100) {
        return LAND_SCRUB_COMPLETED;
    }
    return status;
}

function ResultButton({ data }) {
    const { id, status, percent_completed } = data;
    // Show the results button if status is LAND_SCRUB_COMPLETED even if percent_completed
    // is not 100% for backwards compatibility.
    if (status === LAND_SCRUB_COMPLETED || percent_completed === 100) {
        return (
            <Link className="btn bg-gradient-primary" to={`results/${id}/`}>
                {"View & Download"}
            </Link>
        );
    }
}

export default function LandScrubbing() {
    const [rows, setRows] = useState();

    useEffect(() => {
        let timeoutID;

        const fetchAll = () => {
            fetch("/api/analytics/scrubbing_status/all/")
                .then((value) => {
                    setRows(value);
                    timeoutID = setTimeout(fetchAll, 5000);
                })
                .catch((xhr) => {
                    toast.error(parseErrorResponse(xhr));
                });
        };

        fetchAll();

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, []);

    const columns = [
        {
            field: "input_filename",
            headerName: "File Name",
            valueFormatter: ({ value }) => decodeURIComponent(value),
        },
        { field: "total_rows", headerName: "Rows" },
        { field: "status", headerName: "Status", cellRenderer: Status },
        { field: "status", headerName: "Progress", cellRenderer: Progress, width: 200 },
        {
            field: "created_at",
            headerName: "Upload Date",
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: "price_total",
            headerName: "Paid",
            valueFormatter: ({ data, value }) =>
                data.paid_at != null ? formatCredits(value) : "",
        },
        {
            field: "id",
            headerName: "Actions",
            cellRenderer: ResultButton,
        },
    ];

    return (
        <div>
            <div className="card">
                <div className="card-header pb-0 d-sm-flex justify-content-between">
                    <h5>Land Scrubbing</h5>
                    <Link
                        className="btn bg-gradient-primary mb-0"
                        to="/home/land_scrubbing/new/"
                    >
                        Start A New Scrub
                    </Link>
                </div>
                <div className="card-body">
                    <AGGrid fill_table={true} rows={rows || []} columns={columns} />
                </div>
            </div>
        </div>
    );
}
