import clsx from "clsx";
import { isNumber } from "lodash";
import { Typography } from "@src/land_ui/typography/typography";
import { type FullParcel as OrvalFullParcel } from "@src/orval/gen/model";
import {
    getOwnerFullName,
    ParcelAcresText,
    ParcelApnLabel,
} from "@src/pages/parcel_viewer/controls/detail";
import { SVGViewer } from "@src/pages/parcel_viewer/map_menu/SvgPreviewr";
import { PLACEHOLDER_LABEL } from "@src/pages/parcel_viewer/utils";
import { useParcel } from "@src/hooks/useParcel";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { InView } from "@src/land_ui/in_view/in_view";

interface ExportDetailCardProps {
    parcel: OrvalFullParcel;
    countyLabel: string;
    wetlandsPercent?: number;
    floodZonePercent?: number;
    onSeeDetailClick?: () => void;
}

export function ExportDetailCard({
    parcel,
    countyLabel,
    wetlandsPercent,
    floodZonePercent,
    onSeeDetailClick,
}: ExportDetailCardProps) {
    const { goToParcelInMap, isSelectedParcel, isLoading } = useParcel({
        parcelId: parcel.PropertyID,
    });
    return (
        <div
            className={clsx(
                " lui-m-3 lui-border-gray-200 lui-border lui-border-solid lui-rounded-xl lui-overflow-hidden",
                {
                    "lui-border-gray-600": isSelectedParcel,
                },
            )}
        >
            <div className="lui-flex lui-p-3 lui-items-center lui-justify-between lui-gap-3 ">
                <div>
                    <Typography
                        size="sm"
                        color="gray-700"
                        className="lui-flex lui-items-center lui-gap-1"
                    >
                        <ParcelApnLabel parcel={parcel} />
                    </Typography>
                    <Typography weight="medium" className="lui-mt-1">
                        {countyLabel}
                    </Typography>
                    <div className="lui-mt-1">
                        <ParcelAcresText parcel={parcel} />
                    </div>
                </div>
                <div className="lui-relative lui-w-48 lui-flex lui-justify-end lui-group">
                    {parcel.svg && (
                        <InView
                            style={{ width: "77px", height: "55px" }}
                            className="lui-block group-hover:lui-invisible lui-delay-100 lui-ease-in-out"
                        >
                            <SVGViewer pathString={parcel.svg} />
                        </InView>
                    )}
                    <div className="lui-invisible lui-absolute lui-top-0 lui-right-0 group-hover:lui-visible lui-delay-100 lui-ease-in-out">
                        <Button
                            isLoading={isLoading}
                            onClick={async () => {
                                await goToParcelInMap({ showParcelDetailPanel: true });
                                onSeeDetailClick && onSeeDetailClick();
                            }}
                        >
                            <span className="lui-flex lui-gap-3 lui-items-center">
                                <span>View Details</span>
                                <Icon name="RightArrow" color="inherit" />
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="lui-grid lui-grid-cols-3 lui-border-x-0 lui-border-y-0 lui-border-t lui-border-solid lui-border-gray-100 lui-bg-gray-0">
                <ParcelDetailCol
                    title={getOwnerFullName(parcel)}
                    value={`${parcel.MailingCity}, ${parcel.MailingState}`}
                    className="lui-capitalize"
                    hasRightBorder
                />
                <ParcelDetailCol
                    title={
                        isNumber(wetlandsPercent)
                            ? `${wetlandsPercent}%`
                            : PLACEHOLDER_LABEL
                    }
                    value={"Wetland"}
                    hasRightBorder
                />

                <ParcelDetailCol
                    title={
                        isNumber(floodZonePercent)
                            ? `${floodZonePercent}%`
                            : PLACEHOLDER_LABEL
                    }
                    value={"Flood cover"}
                />
            </div>
        </div>
    );
}

function ParcelDetailCol({
    title,
    value,
    hasRightBorder,
    className,
}: {
    title: string;
    value: string | number;
    hasRightBorder?: boolean;
    className?: string;
}) {
    return (
        <div
            className={clsx(
                "lui-flex lui-flex-col lui-p-3 lui-gap-0.5 lui-h-full lui-justify-between",
                {
                    "lui-border-x-0 lui-border-y-0 lui-border-r  lui-border-solid lui-border-gray-100":
                        hasRightBorder,
                },
                className,
            )}
        >
            <Typography size="sm" weight="medium">
                {title}
            </Typography>
            <Typography size="sm" color="gray-700">
                {value}
            </Typography>
        </div>
    );
}
