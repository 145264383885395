import React, { Component } from "react";

export default class Checkbox extends Component {
    static component_name = "Checkbox";

    constructor(props) {
        super(props);
        this.state = { options: [] };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        const current_selection = this.props.value;
        const selection = e.target.value;

        if (current_selection.includes(selection)) {
            const index = current_selection.indexOf(selection);
            current_selection.splice(index, 1);
        } else {
            current_selection.push(selection);
        }

        const new_state = {};
        new_state[this.props.name] = current_selection;
        this.props.set_form_state(new_state);
    };

    render() {
        let label = null;
        if (this.props.label) {
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        // Check if default value should be used
        let value = this.props.value;
        if (value == "" || value == "undefined") {
            value = this.props.defaultoption;
        }

        const option_dict = this.props.options;
        const options = [];

        // Create JSX for select options
        for (const index in option_dict) {
            let checked = "";
            if (value && value.includes(String(option_dict[index].value))) {
                checked = "checked";
            }

            const options_key = `checkbox_${this.props.name}_${index}`;
            options.push(
                <div key={options_key} className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={options_key}
                        value={String(option_dict[index].value)}
                        checked={checked}
                        onChange={this.handleChange}
                    />
                    <label className="form-check-label" htmlFor={options_key}>
                        {option_dict[index].text}
                    </label>
                </div>,
            );
        }

        // Breaks are the number of items to display per column
        const breaks = this.props.breaks || [];

        // Map breaks to array indices
        // ex. [10, 10, 10] -> [0, 10, 20, 30]
        const indexes = [0];
        for (let i = 0, acc = 0; i < breaks.length; i++) {
            acc += breaks[i];
            indexes.push(acc);
        }

        // Group options into columns
        const cols = [];
        for (let i = 0; i < indexes.length; i++) {
            const start = indexes[i];
            const end = indexes[i + 1];
            cols.push(
                <div key={i} className="col">
                    {options.slice(start, end)}
                </div>,
            );
        }

        return (
            <div
                className={`form-group multiselect text-start ${this.props.className}`}
                style={this.props.style}
            >
                {label}
                <div className="row">{cols}</div>
            </div>
        );
    }
}
