import { createContext, useContext } from "react";

import type {
    User,
    County,
    MapFilter,
    SavedList,
    SearchResult,
    DebugPoint,
} from "./types";

interface SearchResultOptions {
    disableZoom: boolean;
}

export interface OwnerPropertyState {
    ownerParcelId: number;
    ownerParcelList: number[];
}

export interface ParcelViewerContextType {
    setMapFilter: (value: MapFilter) => void;
    county: County;
    setCounty: (value: County) => void;
    savedList: SavedList;
    setSavedList: (value: SavedList) => void;
    setDebugPoints: (points: DebugPoint[]) => void;
    searchResult: SearchResult;
    setSearchResult: (value: SearchResult, options?: SearchResultOptions) => void;
    setActivePolygon: (value?: boolean) => void;
    isPolygonActive: boolean;
    // Owner Property data
    ownerPropertyData?: OwnerPropertyState;
    setOwnerPropertyData: (value: OwnerPropertyState) => void;
}

export const ParcelViewerContext = createContext<ParcelViewerContextType | null>(null);

interface UserContextType {
    user?: User;
}

export const UserContext = createContext<UserContextType | null>(null);

export function useMapContext() {
    const context = useContext(ParcelViewerContext);
    if (!context) {
        throw new Error("useMapContext must be used within a MapProvider");
    }
    return context;
}
