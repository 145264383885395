import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";

import { toast } from "react-toastify";

import { CREDIT_TYPE_CASH } from "constants";
import {
    fetch,
    parseErrorResponse,
    formatDate,
    formatCredits,
    formatMoneyCents,
    getModelURL,
} from "functions";
import { Loading } from "library";
import { AGGrid, GiftCreditsModal, RefundCreditsModal } from "components";

export default function AdminUserDetail() {
    const { id } = useParams();
    const [user, setUser] = useState();
    const [wallet, setWallet] = useState();

    const fetchUser = useCallback(async () => {
        try {
            const user = await fetch(`/api/users/${id}/`);
            setUser(user);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    }, [id]);

    const fetchWallet = useCallback(async () => {
        try {
            const user = await fetch(`/api/users/${id}/wallet/`);
            setWallet(user);
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    }, [id]);

    useEffect(() => {
        fetchUser();
        fetchWallet();
    }, [fetchUser, fetchWallet]);

    return (
        <div className="row">
            <div className="col-lg-9 mx-auto">
                <Profile user={user} />
                <Credits user={user} wallet={wallet} fetchWallet={fetchWallet} />
            </div>
        </div>
    );
}

function Profile({ user }) {
    if (!user) {
        return <Loading />;
    }
    return (
        <div className="card mb-5">
            <div className="card-header pb-0">
                <h5 className="mb-0">User Profile</h5>
            </div>
            <div className="card-body">
                <p>
                    <strong>Name:</strong> {user.first_name} {user.last_name}
                </p>
                <p>
                    <strong>Email:</strong> {user.email}
                </p>
            </div>
        </div>
    );
}

function Credits({ user, wallet, fetchWallet }) {
    const [showGiftModal, setShowGiftModal] = useState(false);
    const [refundRowID, setRefundRowID] = useState("");

    const columns = [
        {
            field: "created_at",
            headerName: "Created",
            valueFormatter: ({ value }) => formatDate(value),
            filter: true,
            cellDataType: "dateString",
        },
        {
            field: "credit",
            headerName: "Credit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            field: "debit",
            headerName: "Debit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            field: "paid_amount",
            headerName: "Paid",
            valueFormatter: ({ value }) =>
                value != null ? formatMoneyCents(value) : "",
        },
        { headerName: "Name", field: "content_type", cellRenderer: ReportURLField },
        { field: "description", headerName: "Description" },
        { field: "note", headerName: "Note" },
        {
            field: "id",
            headerName: "Refund",
            cellRenderer: RefundButton,
            cellRendererParams: {
                onClick: (id) => setRefundRowID(id),
            },
        },
    ];

    const onGiftedCredits = () => {
        setShowGiftModal(false);
        fetchWallet();
    };

    const onRefundedCredits = () => {
        setRefundRowID("");
        fetchWallet();
    };

    if (!user || !wallet) {
        return <Loading />;
    }
    const { transactions } = wallet;

    return (
        <div className="card mb-5">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5 className="mb-0">Credits</h5>
                <button
                    className="btn btn-primary"
                    onClick={() => setShowGiftModal(true)}
                >
                    Gift Credits
                </button>
            </div>
            <div className="card-body">
                {(showGiftModal && (
                    <GiftCreditsModal
                        userID={user.id}
                        onConfirmed={() => onGiftedCredits()}
                        onDismissed={() => setShowGiftModal(false)}
                    />
                )) ||
                    null}
                {(refundRowID !== "" && (
                    <RefundCreditsModal
                        userID={user.id}
                        transactionID={refundRowID}
                        onConfirmed={() => onRefundedCredits()}
                        onDismissed={() => setRefundRowID("")}
                    />
                )) ||
                    null}
                <AGGrid fill_table={true} rows={transactions} columns={columns} />
            </div>
        </div>
    );
}

function ReportURLField({ data }) {
    const { content_type, object_id, report_name } = data;
    const url = getModelURL(content_type, object_id);
    if (url && report_name) {
        return (
            <Link to={url} className="text-decoration-underline">
                {report_name}
            </Link>
        );
    }
    return <>{report_name}</>;
}

function RefundButton({ data, onClick }) {
    const { id, credit_type, debit, refunded } = data;
    const showRefundButton = credit_type === CREDIT_TYPE_CASH && debit > 0;
    if (!showRefundButton) {
        return null;
    }
    return (
        <button
            className="btn btn-secondary"
            onClick={() => onClick(id)}
            disabled={refunded}
        >
            Refund
        </button>
    );
}
