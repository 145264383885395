import { Popup } from "react-map-gl";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { Popover } from "@src/land_ui/popover/popover";
import { Typography } from "@src/land_ui/typography/typography";

export interface MapboxPopupProps {
    longitude: number;
    latitude: number;
    onClose?: () => void;
    children?: React.ReactNode;
    title?: string;
    footer?: React.ReactNode;
    columns?: number;
    offset?: number;
    closeOnMove?: boolean;
    anchor?:
        | "center"
        | "top"
        | "bottom"
        | "left"
        | "right"
        | "top-left"
        | "top-right"
        | "bottom-left"
        | "bottom-right";
}
export default function MapboxPopup({
    title,
    children,
    onClose,
    longitude,
    latitude,
    footer,
    anchor,
    offset,
    columns = 1,
    closeOnMove = true,
}: MapboxPopupProps) {
    return (
        <Popover isOpen={true} setIsOpen={() => onClose()}>
            <Popup
                longitude={longitude}
                latitude={latitude}
                offset={offset}
                onClose={onClose}
                closeOnClick={false}
                closeButton={false}
                closeOnMove={closeOnMove}
                anchor={anchor}
            >
                <div className="lui-mapbox-popup">
                    {Boolean(title) && (
                        <>
                            <div className="lui-flex lui-justify-between lui-items-center lui-p-3">
                                <Typography weight="medium">{title}</Typography>
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        onClose && onClose();
                                    }}
                                    icon="Close"
                                />
                            </div>
                            <Divider horizontal />
                        </>
                    )}

                    <div
                        className={`lui-grid lui-grid-cols-${columns} lui-justify-between lui-gap-x-6 lui-gap-y-3 lui-p-3`}
                    >
                        {children}
                    </div>

                    {footer && (
                        <>
                            <Divider horizontal />
                            <div className="lui-p-3">{footer}</div>
                        </>
                    )}
                </div>
            </Popup>
        </Popover>
    );
}
