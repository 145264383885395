// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars -- side-effect: chart.js/auto registers all chart types
import Chart from "chart.js/auto";

import BarChart from "./bar_chart";

import { zeroPad } from "functions";

export default function UsersChart({ users }) {
    users = users || {};

    const paidUsers = (users && users.paid) || [];
    const abandonedUsers = (users && users.abandoned) || [];

    const labels = paidUsers.map((row) => `${row.year}-${zeroPad(row.month, 2)}`);

    const datasets = [
        { label: "Paid", data: paidUsers.map((row) => row.count) },
        { label: "Abandoned", data: abandonedUsers.map((row) => row.count) },
    ];

    return <BarChart labels={labels} datasets={datasets} />;
}
