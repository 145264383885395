import React, { forwardRef } from "react";
import { Typography } from "@src/land_ui/typography/typography";
import "./input.css";
import { Icon, type IconNameType } from "@src/land_ui/icon/icon";
import clsx from "clsx";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: React.ReactNode;
    srLabel?: string; // Used for screen readers to describe the input
    info?: React.ReactNode;
    placeholder?: string;
    error?: React.ReactNode;
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
    step?: React.InputHTMLAttributes<HTMLInputElement>["step"];
    icon?: IconNameType;
    action?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onEnter?: (value: string) => void;
    autoSelectionOnFocus?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            srLabel,
            info,
            icon,
            placeholder,
            error,
            type = "text",
            action,
            endAdornment,
            onEnter,
            autoSelectionOnFocus,
            ...props
        },
        ref,
    ) => {
        const { className, ...rest } = props;

        return (
            <span className="lui-input__container">
                {(label || action || srLabel) && (
                    <span className="lui-flex lui-justify-between">
                        {label && (
                            <Typography variant="label" weight="medium">
                                {label}
                            </Typography>
                        )}
                        {action && (
                            <Typography variant="span" color="primary-500">
                                {action}
                            </Typography>
                        )}

                        {srLabel && (
                            // Hack to align the input fields &nbsp; but also announce the label to screen readers
                            <span>
                                &nbsp;
                                <span className="lui-sr-only">{srLabel}</span>
                            </span>
                        )}
                    </span>
                )}

                <span
                    className={clsx(
                        "lui-px-4 lui-py-3 lui-input__border lui-rounded-[100px]",
                        {
                            "lui-pl-11": icon,
                            "lui-border-red-700": error,
                            "lui-border-gray-300 focus-within:lui-border-gray-700 hover:lui-border-gray-700":
                                !error,
                        },
                    )}
                >
                    {icon && (
                        <Icon
                            name={icon}
                            className="lui-absolute lui-left-4"
                            color="gray-700"
                        />
                    )}
                    <input
                        ref={ref}
                        className={clsx("lui-input__input-field", className)}
                        type={type}
                        placeholder={placeholder}
                        autoComplete="off"
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && onEnter) {
                                e.preventDefault();
                                onEnter(e.currentTarget.value);
                            }
                        }}
                        onFocus={(e) => {
                            if (autoSelectionOnFocus) {
                                setTimeout(() => {
                                    e.target.select();
                                }, 1);
                            }
                        }}
                        {...rest}
                    />
                    {endAdornment}
                </span>
                {(Boolean(error) || Boolean(info)) && (
                    <span>
                        {error && (
                            <Typography
                                size="sm"
                                variant="span"
                                color="red-700"
                                className="lui-flex lui-gap-1.5 lui-items-center"
                            >
                                <Icon name="Announcement" color="inherit" size="sm" />
                                {error}
                            </Typography>
                        )}
                        {/* Hide the extra info if there is an error message */}
                        {info && !error && (
                            <Typography
                                size="sm"
                                variant="span"
                                color="gray-700"
                                className="lui-flex lui-gap-1.5 lui-items-center"
                            >
                                {info}
                            </Typography>
                        )}
                    </span>
                )}
            </span>
        );
    },
);
