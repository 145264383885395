import clsx from "clsx";

import { CloseIcon } from "./icons/CloseIcon";
import { HamburgerIcon } from "./icons/HamburgerIcon";
import { FilterIcon } from "./icons/FilterIcon";
import { ChevronDownIcon } from "./icons/ChevronDownIcon";
import { QuestionMarkIcon } from "./icons/QuestionMarkIcon";
import { LayerTwoIcon } from "./icons/LayerTwoIcon";
import { ToolIcon } from "./icons/ToolIcon";
import { GlobeIcon } from "./icons/GlobeIcon";
import { PlusIcon } from "./icons/PlusIcon";
import { MinusIcon } from "./icons/MinusIcon";
import { PinIcon } from "./icons/PinIcon";
import { AnnouncementIcon } from "./icons/AnnouncementIcon";
import { VideoIcon } from "./icons/videoIcon";
import { LifebuoyIcon } from "./icons/LifebuoyIcon";
import { BezierCurveIcon } from "./icons/BezierCurveIcon";
import { CursorIcon } from "./icons/CursorIcon";
import { ScissorsIcon } from "./icons/ScissorsIcon";
import { TransformIcon } from "./icons/TransformIcon";
import { RightArrowIcon } from "./icons/RightArrowIcon";
import { SearchIcon } from "./icons/SearchIcon";
import { DotsHorizontalIcon } from "./icons/DotsHorizontalIcon";
import { LeftArrowIcon } from "./icons/LeftArrowIcon";
import { PenIcon } from "./icons/PenIcon";
import { PenPlusIcon } from "./icons/PenPlusIcon";
import { PenMinusIcon } from "./icons/PenMinusIcon";
import { TrashIcon } from "./icons/TrashIcon";
import { CopyIcon } from "./icons/CopyIcon";
import { CircleCheckIcon } from "./icons/CheckCircle";
import { ChevronUpIcon } from "./icons/ChevronUpIcon";
import { CheckIcon } from "./icons/CheckIcon";
import { InfoCircleIcon } from "./icons/InfoCircleIcon";
import { getColorClassName, type LuiColors } from "@src/land_ui/color/color";
import { LandInsightsIcon } from "./icons/LandInsightsIcon";
import { RulerIcon } from "./icons/RulerIcon";
import { ArrowUpRightIcon } from "./icons/ArrowUpRightIcon";
import { forwardRef } from "react";
import { MapMarkIcon } from "./icons/MapMarkIcon";

export const IconList = [
    "Close",
    "Hamburger",
    "Filter",
    "ChevronDown",
    "ChevronUp",
    "QuestionMark",
    "LayerTwo",
    "Tool",
    "Globe",
    "Plus",
    "Minus",
    "Pin",
    "Announcement",
    "Video",
    "Lifebuoy",
    "BezierCurve",
    "Cursor",
    "Scissors",
    "Transform",
    "RightArrow",
    "Search",
    "DotsHorizontal",
    "LeftArrow",
    "Pen",
    "PenPlus",
    "PenMinus",
    "Trash",
    "Copy",
    "CircleCheck",
    "Check",
    "InfoCircle",
    "LandInsights",
    "Ruler",
    "ArrowUpRight",
    "MapMarkIcon",
] as const;

export type IconNameType = (typeof IconList)[number];

export type IconSizeType = "sm" | "md" | "lg";

export interface IconProps {
    name: IconNameType;
    size?: IconSizeType;
    color?: LuiColors;
    className?: string;
}

const sizeMap = {
    sm: "16px",
    md: "24px",
    lg: "36px",
};

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
    ({ name, className, size = "md", color = "gray-1000" }, ref) => {
        const icon = getIcon(name);
        return (
            <span
                ref={ref}
                className={clsx("lui-icon", className, getColorClassName(color))}
                style={{
                    width: sizeMap[size],
                    height: sizeMap[size],
                    display: "inline-block",
                    padding: 0,
                    margin: 0,
                    lineHeight: 0,
                }}
            >
                {icon}
            </span>
        );
    },
);

function getIcon(name: IconNameType) {
    switch (name) {
        case "Close":
            return <CloseIcon />;

        case "Hamburger":
            return <HamburgerIcon />;
        case "Filter":
            return <FilterIcon />;
        case "ChevronDown":
            return <ChevronDownIcon />;
        case "QuestionMark":
            return <QuestionMarkIcon />;
        case "LayerTwo":
            return <LayerTwoIcon />;
        case "Tool":
            return <ToolIcon />;
        case "Globe":
            return <GlobeIcon />;
        case "Plus":
            return <PlusIcon />;
        case "Minus":
            return <MinusIcon />;
        case "Pin":
            return <PinIcon />;
        case "Announcement":
            return <AnnouncementIcon />;
        case "Video":
            return <VideoIcon />;
        case "Lifebuoy":
            return <LifebuoyIcon />;
        case "BezierCurve":
            return <BezierCurveIcon />;
        case "Cursor":
            return <CursorIcon />;
        case "Scissors":
            return <ScissorsIcon />;
        case "Transform":
            return <TransformIcon />;
        case "RightArrow":
            return <RightArrowIcon />;
        case "Search":
            return <SearchIcon />;
        case "DotsHorizontal":
            return <DotsHorizontalIcon />;
        case "LeftArrow":
            return <LeftArrowIcon />;
        case "Pen":
            return <PenIcon />;
        case "PenPlus":
            return <PenPlusIcon />;
        case "PenMinus":
            return <PenMinusIcon />;
        case "Trash":
            return <TrashIcon />;
        case "Copy":
            return <CopyIcon />;
        case "CircleCheck":
            return <CircleCheckIcon />;
        case "ChevronUp":
            return <ChevronUpIcon />;
        case "Check":
            return <CheckIcon />;
        case "InfoCircle":
            return <InfoCircleIcon />;

        case "LandInsights":
            return <LandInsightsIcon />;

        case "Ruler":
            return <RulerIcon />;

        case "ArrowUpRight":
            return <ArrowUpRightIcon />;

        case "MapMarkIcon":
            return <MapMarkIcon />;

        default:
            return null;
    }
}
