import React from "react";
import { get_children } from "functions";

const VOID_ELEMENTS = ["hr", "br"];

function get_all_children(component, parentFunctions, formState) {
    const context = component.props;
    const topChildren = get_children(context);
    const components = [];

    Object.keys(topChildren).forEach((index) => {
        const childComponent = topChildren[index];

        let dataMapping = parentFunctions;
        if (formState) {
            dataMapping = getFormProps(
                formState,
                childComponent,
                dataMapping,
                index,
                component.props.autoFocus,
            );
        }

        let new_children = null;
        if (
            typeof childComponent.props.children === "string" ||
            childComponent.props.children instanceof String
        ) {
            //The child is a string and should not be overwritten
            new_children = childComponent.props.children;
        } else {
            new_children = get_all_children(childComponent, parentFunctions, formState);
        }

        let componentInstance = null;
        if (
            typeof childComponent.type == "string" &&
            VOID_ELEMENTS.includes(childComponent.type)
        ) {
            componentInstance = React.cloneElement(childComponent);
        } else {
            componentInstance = React.cloneElement(childComponent, {}, new_children);
            //Generic HTML components do not need props mapped
            if (typeof childComponent.type !== "string") {
                componentInstance = React.cloneElement(
                    childComponent,
                    dataMapping,
                    new_children,
                );
            }
        }

        components.push(componentInstance);
    });

    return components;
}

function getFormProps(state, component, data, index, autoFocus) {
    if (component.props && component.props.name) {
        const value = state[component.props.name];

        data.value = value;
        if (index === 0 && autoFocus) {
            data.autoFocus = true;
        }
    }

    return data;
}

export default get_all_children;
