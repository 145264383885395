// prettier-ignore
/* eslint-disable */
import {  HEAP_ANALYTICS_ID, INTERCOM_APP_ID } from "settings";

(window.heap = window.heap || []),
    (heap.load = function (e, t) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        var r = document.createElement("script");
        (r.type = "text/javascript"),
            (r.async = !0),
            (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".jsx");
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r, a);
        for (
            var n = function (e) {
                    return function () {
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                    };
                },
                p = [
                    "addEventProperties",
                    "addUserProperties",
                    "clearEventProperties",
                    "identify",
                    "resetIdentity",
                    "removeEventProperty",
                    "setEventProperties",
                    "track",
                    "unsetEventProperty",
                ],
                o = 0;
            o < p.length;
            o++
        )
            heap[p[o]] = n(p[o]);
    });
window.heap.load(HEAP_ANALYTICS_ID);

(function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
    } else {
        var d = document;
        var i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + INTERCOM_APP_ID;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
            l();
        } else if (w.attachEvent) {
            w.attachEvent("onload", l);
        } else {
            w.addEventListener("load", l, false);
        }
    }
})();
