import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { type IconNameType } from "@src/land_ui/icon/icon";
import { type PlacementOptionsType, Popover } from "@src/land_ui/popover/popover";
import { Tooltip } from "@src/land_ui/tooltip/tooltip";
import { Typography } from "@src/land_ui/typography/typography";

interface MapControlPopoverProps {
    icon: IconNameType;
    tooltipContent: string;
    title: string;
    children: React.ReactNode;
    placement?: PlacementOptionsType;
    dismissOnClick?: boolean;
}

export function ControlPopover({
    tooltipContent,
    icon,
    title,
    placement,
    children,
    dismissOnClick,
}: MapControlPopoverProps) {
    return (
        <Popover
            dismissOnClick={dismissOnClick}
            trigger={
                <div>
                    <Tooltip
                        trigger={<Button variant="secondary" icon={icon} />}
                        content={tooltipContent}
                        placement={placement}
                    />
                </div>
            }
            placement={placement}
        >
            <div className="lui-flex lui-flex-col lui-py-2 lui-bg-white lui-rounded-[24px] lui-shadow-lg lui-min-w-[260px] lui-max-w-[460px] lui-overflow-hidden">
                <Typography weight="bold" className="lui-py-2 lui-px-4">
                    {title}
                </Typography>
                <Divider horizontal />
                <div className="lui-flex lui-flex-col lui-w-full">{children}</div>
            </div>
        </Popover>
    );
}
