import { clsx } from "clsx";

import "./badge.css";
import { Typography, type TypographyProps } from "@src/land_ui/typography/typography";
import { type LuiColors } from "@src/land_ui/color/color";

export type BadgeProps = {
    children: React.ReactNode;
    variant: "danger" | "warning" | "info";
    className?: string;
    size?: TypographyProps["size"];
};

export function Badge({
    className,
    children,
    variant = "info",
    size = "xs",
}: BadgeProps) {
    const variantColor: Record<BadgeProps["variant"], LuiColors> = {
        warning: "orange-900",
        danger: "red-800",
        info: "primary-800",
    };
    const variantBgColor: Record<BadgeProps["variant"], string> = {
        warning: "lui-bg-orange-100",
        danger: "lui-bg-red-100",
        info: "lui-bg-primary-100",
    };

    return (
        <Typography
            variant="span"
            size={size}
            color={variantColor[variant]}
            className={clsx(
                variantBgColor[variant],
                "lui-badge",
                "lui-bg-orange-200",
                "lui-rounded-xl",
                className,
            )}
        >
            {children}
        </Typography>
    );
}
