import { zodResolver } from "@hookform/resolvers/zod";
import { bbox, combine, featureCollection } from "@turf/turf";
import { first, get, isArray, toNumber, toString } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";
import * as z from "zod";
import { fetch, fetchMapboxFeature, formatNumber } from "@src/functions";
import { type MapboxSuggestion } from "@src/functions/mapbox_search";
import {
    INTEGER_NUMBER_VALIDATOR,
    INTEGER_PERCENT_VALIDATOR,
    NUMBER_VALIDATOR,
    POSITIVE_INTEGER_VALIDATOR,
    POSITIVE_NUMBER_VALIDATOR,
    US_ZIP_CODE_VALIDATOR,
} from "@src/functions/validation_utils";
import { Accordion } from "@src/land_ui/accordion/accordion";
import { Autocomplete, useAutocomplete } from "@src/land_ui/autocomplete/autocomplete";
import { Badge } from "@src/land_ui/badge/badge";
import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Input } from "@src/land_ui/input/input";
import { SelectionGroupFormController } from "@src/land_ui/selection_group/selection_group";
import { Sidebar, useSidebar } from "@src/land_ui/sidebar/sidebar";
import { Toggle } from "@src/land_ui/toggle_button/Toggle";
import { Typography } from "@src/land_ui/typography/typography";
import { useParcelsSearchRetrieve } from "@src/orval/gen/api";
import {
    type ParcelExportSkipTraceReportType,
    type ParcelsListParams,
} from "@src/orval/gen/model";
import {
    UserContext,
    ParcelViewerContext,
    useMapContext,
} from "@src/pages/parcel_viewer/context";
import {
    findFeatureOnMap,
    searchMapbox,
} from "@src/pages/parcel_viewer/controls/search";
import {
    type AutocompleteResult,
    type CountyOption,
    type SearchResult,
} from "@src/pages/parcel_viewer/types";
import { type FeaturePolygon, usePolygon } from "@src/components/map_tools/map_polygon";
import { TagList } from "@src/components/tag_list/tag_list";
import { MapFilterParcel } from "./map_filter_parcel_list";
import { type SaveFilterParcelsListParams } from "./save_filter_modal";
import { useCountdown } from "usehooks-ts";
import clsx from "clsx";

const LARGE_SEARCH_WARNING_DELAY_SECONDS = 10;

const PolygonGeometryEnum = z.enum(["Polygon", "MultiPolygon"]);
const FormSchema = z
    .object({
        county: z
            .object(
                {
                    id: z.string().optional(),
                    label: z.string().optional(),
                    inputValue: z.string().optional(),
                    data: z.any().optional(),
                },
                { message: "Please select a county" },
            )
            .or(z.null()),

        polygons: z
            .array(
                z.object({
                    id: z.string(),
                    type: z.enum(["Feature"]).optional(),
                    properties: z.record(z.string(), z.string()).or(z.null()),
                    geometry: z.object({
                        coordinates: z
                            .array(z.array(z.array(z.array(z.number()))))
                            .or(z.array(z.array(z.array(z.number())))),
                        type: PolygonGeometryEnum,
                    }),
                }),
            )
            .optional(),

        // Subdivision logic
        subdivisionList: z.array(z.string().optional()).optional(),

        zoningType: z.string().optional(),
        acresFrom: POSITIVE_NUMBER_VALIDATOR,
        acresTo: POSITIVE_NUMBER_VALIDATOR,
        improvementPercentage: NUMBER_VALIDATOR,
        excludeHOA: z.boolean().optional(),
        showExcludeZip: z.boolean().optional(),
        owner: z.string().min(5).optional(),
        structure: z.string().optional(),
        other: z.string().optional(),
        skipTracing: z.string().optional(),
        scrubbing: z.string().optional(),

        // ZipCode logic
        zipCode: US_ZIP_CODE_VALIDATOR,
        excludeZipCode: US_ZIP_CODE_VALIDATOR,
        includedZipCodes: z.array(z.string()),
        excludeZipCodes: z.array(z.string()),

        // Structure
        aiVacant: z.boolean().optional(),
        minTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        maxTotalSqFt: POSITIVE_NUMBER_VALIDATOR,
        minTotalStructureCount: POSITIVE_NUMBER_VALIDATOR,
        maxTotalStructureCount: POSITIVE_NUMBER_VALIDATOR,

        // owner
        outOfStateOwner: z.boolean().optional(),
        outOfCountyOwner: z.boolean().optional(),
        outOfZipOwner: z.boolean().optional(),
        excludeCorpOwner: z.boolean().optional(),
        excludeKeyword: z.string().optional(),
        excludeKeywords: z.array(z.string()),
        includeKeyword: z.string().optional(),
        includeKeywords: z.array(z.string()),
        interFamilyFlag: z.boolean().optional(),
        minOwnershipLengthInMonths: INTEGER_NUMBER_VALIDATOR,
        maxOwnershipLengthInMonths: INTEGER_NUMBER_VALIDATOR,

        // scrubbing
        removeLandLockedParcels: z.boolean().optional(),
        removeDuplicateParcels: z.boolean().optional(),
        deduplicatingType: z.enum(["smaller", "larger"]).optional(),
        maxWetlandCoverage: INTEGER_PERCENT_VALIDATOR,
        maxFloodCoverage: INTEGER_PERCENT_VALIDATOR,
        minRoadFrontage: POSITIVE_INTEGER_VALIDATOR,
        isScrubDnc: z.boolean().optional(),

        // Other
        schoolDistrict: z.string().optional(),

        // Skip Tracing
        skipTracingType: z.enum(["", "standard", "premium"]).nullable().optional(),
    })
    .superRefine((data, ctx) => {
        if (data.polygons.length > 0) {
            if (!data.acresFrom && !data.acresTo) {
                ctx.addIssue({
                    message: "This field is required",
                    code: z.ZodIssueCode.custom,
                    path: ["acresFrom"],
                });
                ctx.addIssue({
                    message: "This field is required",
                    code: z.ZodIssueCode.custom,
                    path: ["acresTo"],
                });
            }
        }
    });

type FormSchemaType = z.infer<typeof FormSchema>;
const DEFAULT_FORM_VALUES: FormSchemaType = {
    polygons: [],
    county: null,
    outOfCountyOwner: false,
    outOfStateOwner: false,
    outOfZipOwner: false,
    excludeCorpOwner: false,
    excludeKeywords: [],
    includeKeywords: [],
    interFamilyFlag: false,
    includedZipCodes: [],
    excludeZipCodes: [],
    subdivisionList: [],
    acresFrom: null,
    acresTo: null,
    improvementPercentage: null,
    excludeHOA: false,
    aiVacant: false,
    minTotalSqFt: null,
    maxTotalSqFt: null,
    minTotalStructureCount: null,
    maxTotalStructureCount: null,
    minOwnershipLengthInMonths: null,
    maxOwnershipLengthInMonths: null,
    showExcludeZip: false,
    removeLandLockedParcels: false,
    removeDuplicateParcels: false,
    maxWetlandCoverage: null,
    maxFloodCoverage: null,
    minRoadFrontage: null,
};

interface MapFilterProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function formatFeature(feature: any) {
    return {
        id: toString(feature.id),
        type: feature.type,
        properties: feature.properties,
        geometry: {
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
        },
    };
}

export function MapFilter({ isOpen, setIsOpen }: MapFilterProps) {
    const { setSearchResult, savedList, setSavedList, isPolygonActive } =
        useMapContext();
    const [isParcelList, setIsParcelListHandler] = useState<boolean>(false);
    const map = useMap();
    const { polygon, SettingTooltip, deletePolygon, syncPolygons, setPolygonActive } =
        usePolygon({
            onError: () => {
                syncPolygons(methods.getValues().polygons as FeaturePolygon[]);
            },
            onCreate: (events) => {
                const updatedPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    if (updatedPolygons.findIndex((e) => e.id === feature.id) > -1) {
                        return;
                    }

                    updatedPolygons.push(formatFeature(feature));
                });

                methods.setValue("polygons", updatedPolygons);

                // Reset the county when a new polygon is created
                methods.setValue("county", null);
            },
            onUpdate(event) {
                let updatedPolygons = [...methods.getValues().polygons];
                event.features.forEach((feature) => {
                    updatedPolygons = updatedPolygons.map((f) =>
                        f.id === feature.id ? formatFeature(feature) : f,
                    );
                });
                methods.setValue("polygons", updatedPolygons);
            },
            onDelete: (events) => {
                let newPolygons = [...methods.getValues().polygons];
                events.features.forEach((feature) => {
                    newPolygons = newPolygons.filter((f) => f.id !== feature?.id);
                });
                methods.setValue("polygons", newPolygons);

                if (newPolygons.length === 0) {
                    setSearchResult(null);
                }
            },
        });

    const defaultValues = useMemo(() => {
        const hasPolygons =
            Boolean(savedList?.search_filters?.polygons) &&
            savedList?.search_filters?.polygons?.length > 0;
        if (Boolean(savedList?.search_filters?.county) || hasPolygons) {
            const savedFilter = savedList.search_filters;
            if (hasPolygons) {
                // @ts-ignore
                map.current?.fitBounds(bbox(first(savedFilter.polygons)), {
                    maxZoom: 11,
                    padding: 100,
                });
                syncPolygons(savedList?.search_filters?.polygons);
            }

            return {
                // Acres
                acresFrom: numberOrNull(savedFilter.acres_min),
                acresTo: numberOrNull(savedFilter.acres_max),

                // AI Vacant
                aiVacant: Boolean(savedFilter.ai_vacant),

                // Square feet
                maxTotalSqFt: numberOrNull(savedFilter.sq_ft_max),
                minTotalSqFt: numberOrNull(savedFilter.sq_ft_min),

                // Structure
                minTotalStructureCount: numberOrNull(savedFilter.structure_count_min),
                maxTotalStructureCount: numberOrNull(savedFilter.structure_count_max),

                improvementPercentage: numberOrNull(
                    savedFilter.improvement_percentage_max,
                ),
                excludeHOA: Boolean(savedFilter.exclude_hoa),

                // Owner section
                outOfStateOwner: Boolean(savedFilter.out_of_state_owner),
                outOfCountyOwner: Boolean(savedFilter.out_of_county_owner),
                outOfZipOwner: Boolean(savedFilter.out_of_zip_owner),
                excludeCorpOwner: Boolean(savedFilter.exclude_corp_owner),
                excludeKeywords:
                    isArray(savedFilter?.exclude_keywords) &&
                    savedFilter?.exclude_keywords.length > 0
                        ? savedFilter?.exclude_keywords.filter((e) => Boolean(e))
                        : [],
                includeKeywords:
                    isArray(savedFilter?.include_keywords) &&
                    savedFilter?.include_keywords.length > 0
                        ? savedFilter?.include_keywords.filter((e) => Boolean(e))
                        : [],
                interFamilyFlag: Boolean(savedFilter.inter_family_flag),
                minOwnershipLengthInMonths: numberOrNull(
                    savedFilter.ownership_length_min,
                ),
                maxOwnershipLengthInMonths: numberOrNull(
                    savedFilter.ownership_length_max,
                ),

                // Zipcode section
                includedZipCodes: isArray(savedFilter?.zips_include)
                    ? savedFilter?.zips_include.filter((e) => Boolean(e))
                    : [],

                excludeZipCodes:
                    isArray(savedFilter?.zips_exclude) &&
                    savedFilter?.zips_exclude.length > 0
                        ? savedFilter?.zips_exclude.filter((e) => Boolean(e))
                        : [],

                // County autocomplete
                county: Boolean(savedFilter.countyOption)
                    ? {
                          id: savedFilter.countyOption.id,
                          label: savedFilter.countyOption.label,
                          inputValue: savedFilter.countyOption.label,
                          data:
                              // @ts-ignore
                              savedFilter.countyOption?.data ||
                              savedFilter.countyOption,
                      }
                    : null,
                polygons: savedFilter.polygons || [],
            };
        }

        return DEFAULT_FORM_VALUES;
    }, [map, savedList?.search_filters, syncPolygons]);

    const setIsParcelList = useCallback(
        (value: boolean) => {
            setIsParcelListHandler(value);
            // Disable the polygon drawing when the parcel list is open
            setPolygonActive(!value);
        },
        [setPolygonActive],
    );

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        mode: "onBlur",
        defaultValues,
    });
    const formValues = methods.watch();

    const parcelParams = useMemo<SaveFilterParcelsListParams>(() => {
        const countySelectedOption = formValues.county as CountyOption;

        // @ts-ignore
        const combined = combine(featureCollection(formValues.polygons));

        return {
            geom_intersects:
                isArray(formValues?.polygons) && Boolean(combined)
                    ? JSON.stringify(combined.features[0]?.geometry)
                    : null,
            county: countySelectedOption?.id || "",
            county_label: countySelectedOption?.label || "",
            subdivision: formValues.subdivisionList,
            zips_include: formValues.includedZipCodes,
            zips_exclude: formValues.excludeZipCodes,
            zoning: formValues.zoningType,
            acres_min: formValues.acresFrom,
            acres_max: formValues.acresTo,
            improvement_percentage_max: formValues.improvementPercentage,
            exclude_hoa: formValues.excludeHOA || null,
            out_of_state_owner: formValues.outOfStateOwner || null,
            out_of_county_owner: formValues.outOfCountyOwner || null,
            out_of_zip_owner: formValues.outOfZipOwner || null,
            exclude_corp_owner: formValues.excludeCorpOwner || null,
            exclude_keywords: formValues.excludeKeywords || null,
            include_keywords: formValues.includeKeywords || null,
            inter_family_flag: formValues.interFamilyFlag || null,
            ownership_length_min: formValues.minOwnershipLengthInMonths,
            ownership_length_max: formValues.maxOwnershipLengthInMonths,
            ai_vacant: formValues.aiVacant,
            sq_ft_min: formValues.minTotalSqFt,
            sq_ft_max: formValues.maxTotalSqFt,
            structure_count_min: formValues.minTotalStructureCount,
            structure_count_max: formValues.maxTotalStructureCount,
            state: countySelectedOption?.state,

            year_built_min: null,
            year_built_max: null,
            owner_occupied: null,

            countyOption: countySelectedOption,
        };
    }, [formValues]);

    function resetForm() {
        setSavedList(null);
        setSearchResult(null);
        methods.reset(DEFAULT_FORM_VALUES);
    }

    useEffect(() => {
        if (!isOpen && !isParcelList) {
            setPolygonActive(false);
        }

        if (isOpen && !isParcelList) {
            setPolygonActive(true);
        }
    }, [isOpen, isParcelList, setPolygonActive]);

    return (
        <FormProvider {...methods}>
            <form>
                <Sidebar
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    removeBackdrop
                    onClose={() => {
                        setSearchResult(null);
                        setSavedList({
                            id: "temp-search-filter",
                            title: "temp-search-filter",
                            search_filters: parcelParams,
                        });
                    }}
                    className={clsx({
                        "lui-cursor-crosshair": isPolygonActive,
                    })}
                >
                    {SettingTooltip}

                    {/* Export parce list menu  */}
                    {isParcelList ? (
                        <MapFilterParcel
                            parcelParams={parcelParams}
                            setIsParcelList={setIsParcelList}
                            // TODO: once we add strict types in typescript we won't need to cast this
                            countyOption={methods.watch("county")?.data as CountyOption}
                            polygons={methods.watch("polygons") as FeaturePolygon[]}
                            resetFilterForm={resetForm}
                            landScrubs={{
                                flood_zone_allowed: numberOrNull(
                                    formValues.maxFloodCoverage,
                                ),
                                wetlands_allowed: numberOrNull(
                                    formValues.maxWetlandCoverage,
                                ),
                                road_frontage: numberOrNull(formValues.minRoadFrontage),
                                land_locked: formValues.removeLandLockedParcels,
                                scrub_duplicates: formValues.removeDuplicateParcels,
                                size_preference: formValues.deduplicatingType,
                                created_at: null,
                                updated_at: null,
                            }}
                            skipTrace={{
                                scrub_dnc: formValues.isScrubDnc,
                                report_type:
                                    // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                                    formValues.skipTracingType as unknown as ParcelExportSkipTraceReportType,
                                created_at: null,
                                updated_at: null,
                            }}
                        />
                    ) : (
                        <>
                            <Sidebar.Header preventAutoFocus>
                                Filter Parcels
                            </Sidebar.Header>
                            <Sidebar.Content>
                                <PolygonAccordion
                                    polygon={polygon}
                                    deletePolygon={deletePolygon}
                                />
                                <ParcelFormAccordion />
                                <OwnerFormAccordion />
                                <StructureFormAccordion />
                                <ScrubbingFormAccordion />
                                <SkipTracingFormAccordion />
                            </Sidebar.Content>
                            <FormFooter
                                parcelParams={parcelParams}
                                setIsParcelList={setIsParcelList}
                                resetForm={resetForm}
                            />
                        </>
                    )}
                </Sidebar>
            </form>
        </FormProvider>
    );
}

interface PolygonAccordionProps {
    polygon: MapboxDraw;
    deletePolygon: (id: FeaturePolygon[]) => void;
}

function PolygonAccordion({ polygon, deletePolygon }: PolygonAccordionProps) {
    const { isPolygonActive } = useMapContext();
    const map = useMap();
    const { watch } = useFormContext<FormSchemaType>();
    const polygons = watch("polygons");

    return (
        <div className="lui-p-6 lui-pb-0">
            <div className="lui-flex lui-justify-between lui-items-center">
                <Typography variant="h6" size="lg" weight="bold">
                    Polygons
                </Typography>
                <Button
                    variant="base"
                    onClick={() => {
                        polygon.changeMode("draw_polygon");
                    }}
                >
                    <span
                        className={clsx({
                            "lui-cursor-crosshair": isPolygonActive,
                        })}
                    >
                        <Icon name="PenPlus" color="primary-500" />{" "}
                        <Typography color="primary-500" variant="span" weight="medium">
                            Add zone
                        </Typography>
                    </span>
                </Button>
            </div>
            <div className="lui-mt-2 lui-flex lui-flex-col lui-gap-2">
                {polygons.map((feature, index) => {
                    return (
                        <div
                            key={index}
                            className="lui-flex lui-gap-3 lui-items-center lui-justify-between"
                        >
                            <div className="lui-flex lui-gap-2 lui-items-center">
                                <Icon name="PenPlus" color="primary-500" />
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        // @ts-ignore
                                        map.current?.fitBounds(bbox(feature), {
                                            maxZoom: 11,
                                            padding: 100,
                                        });
                                    }}
                                >
                                    <Typography>Zone {index + 1}</Typography>
                                </Button>
                            </div>
                            <Button
                                variant="base"
                                onClick={() => {
                                    // @ts-ignore
                                    deletePolygon([feature]);
                                }}
                                icon="Trash"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function FormFooter({
    setIsParcelList,
    parcelParams,
    resetForm,
}: {
    setIsParcelList: (value: boolean) => void;
    parcelParams: ParcelsListParams;
    resetForm: () => void;
}) {
    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: LARGE_SEARCH_WARNING_DELAY_SECONDS,
    });

    const { isOpen } = useSidebar();
    const {
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useFormContext<FormSchemaType>();
    const [showErrorBox, setShowErrorBox] = useState(true);
    const { searchResult, setSearchResult } = useMapContext();
    const {
        isLoading,
        refetch: refetchParcelSearch,
        error,
    } = useParcelsSearchRetrieve(parcelParams, {
        query: {
            // Disable this query and only trigger once its refetched
            enabled: false,
            retry: false,
        },
    });

    const refetch = useDebouncedCallback(async () => {
        if (!isValid || isLoading) return;
        startCountdown();
        const newSearchResult = await refetchParcelSearch();
        resetCountdown();
        const formValues = watch();
        if (newSearchResult) {
            setSearchResult(newSearchResult.data as unknown as SearchResult, {
                disableZoom: formValues.polygons.length > 0,
            });
        }
    }, 500);

    // Listen to changes in the form so we can refetch the data
    const values = watch([
        // Parcel
        "county",
        "subdivisionList",
        "excludeZipCodes",
        "includedZipCodes",
        "acresFrom",
        "acresTo",
        "improvementPercentage",
        "excludeHOA",
        // Owner
        "owner",
        "outOfStateOwner",
        "outOfCountyOwner",
        "outOfZipOwner",
        "excludeCorpOwner",
        "excludeKeywords",
        "includeKeywords",
        "interFamilyFlag",
        "minOwnershipLengthInMonths",
        "maxOwnershipLengthInMonths",
        // Structure
        "aiVacant",
        "minTotalSqFt",
        "maxTotalSqFt",
        "minTotalStructureCount",
        "maxTotalStructureCount",

        // Polygon
        "polygons",
    ]);

    /**
     * Refetch the data when the values change
     */
    useEffect(() => {
        const formValues = watch();
        if ((formValues.polygons.length > 0 || formValues?.county?.id) && isOpen) {
            if (
                formValues.acresFrom ||
                formValues.acresTo ||
                formValues.subdivisionList?.length > 0 ||
                formValues.includedZipCodes?.length > 0 ||
                formValues.excludeZipCodes?.length > 0 ||
                formValues.excludeZipCodes?.length > 0
            ) {
                refetch();
            }
        }

        // This is hack to only refetch when the values change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(values)]);
    const hasErrors = Object.keys(errors).length > 0;

    /**
     * Show the error box when there are errors since its dismissible
     */
    useEffect(() => {
        if (hasErrors) {
            setShowErrorBox(true);
        }
    }, [errors, hasErrors]);

    const onSubmit = async () => {
        setIsParcelList(true);
    };

    return (
        <Sidebar.Footer fluid className="lui-p-0">
            {hasErrors && showErrorBox && (
                <div className="lui-border lui-bg-red-200  lui-rounded-lg lui-p-6">
                    <div className="lui-flex lui-gap-2">
                        <Typography>
                            <Icon name="InfoCircle" size="sm" color="red-700" />
                        </Typography>
                        <Typography size="sm" weight="medium">
                            Some fields are missing or contain errors. Please review the
                            highlighted fields.
                        </Typography>
                        <Button
                            variant="base"
                            icon="Close"
                            onClick={() => setShowErrorBox(false)}
                        />
                    </div>
                    <ul className="list-disc lui-mt-2.5">
                        {Object.keys(errors).map((key) => {
                            return (
                                <Typography
                                    key={key}
                                    size="sm"
                                    variant="li"
                                    color="black"
                                    className="lui-mt-1"
                                >
                                    <span className="lui-border-y-0 lui-border-x-0 lui-border-b lui-border-black lui-border-solid">
                                        {get(fieldKeyToTitle, key, key)}
                                    </span>{" "}
                                    {get(errors, `${key}.message`)}
                                </Typography>
                            );
                        })}
                    </ul>
                </div>
            )}

            {count === 0 && (
                <WarningMessage message="Seems like you made a large search. Hang tight while we crunch the numbers." />
            )}

            {searchResult?.more_results && !isLoading && (
                <WarningMessage message="Your search results were cut short. We suggest adding more filters to see the full list of parcels." />
            )}

            {error && (
                <div className="lui-bg-red-200 lui-border-l-4 lui-border-orange-500  lui-p-4">
                    <div className="lui-flex lui-gap-2">
                        <Icon name="InfoCircle" size="sm" color="red-900" />
                        <Typography size="sm" weight="medium" color="red-900">
                            Api query failed, please try again later.
                        </Typography>
                    </div>
                </div>
            )}

            <div className="lui-flex lui-gap-4 lui-justify-between lui-p-6">
                <Button className="" variant="inline" onClick={resetForm}>
                    Clear filter
                </Button>
                <div className=" lui-flex lui-justify-end">
                    <Button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={
                            isLoading ||
                            (searchResult?.results &&
                                searchResult?.results?.length === 0) ||
                            searchResult?.more_results
                        }
                        isLoading={isLoading}
                        className="lui-min-w-40"
                    >
                        {!searchResult && "Filter Parcels"}
                        {searchResult?.results?.length >= 0 &&
                            `Export ${formatNumber(searchResult?.count)} Parcels`}
                    </Button>
                </div>
            </div>
        </Sidebar.Footer>
    );
}

function WarningMessage({ message }: { message: string }) {
    return (
        <div className="lui-bg-orange-100 lui-border-l-4 lui-border-orange-500  lui-p-4">
            <div className="lui-flex lui-gap-2">
                <Icon name="InfoCircle" size="sm" color="orange-900" />
                <Typography size="sm" weight="medium" color="orange-900">
                    {message}{" "}
                </Typography>
            </div>
        </div>
    );
}

function ParcelFormAccordion() {
    const {
        watch,
        register,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(true);
    const { current: map } = useMap();
    const { setMapFilter, setSearchResult } = useContext(ParcelViewerContext);

    const countyAutocompleteProps = useAutocomplete<CountyOption>({
        id: "county-search-autocomplete",
        placeholder: "Enter county name...",
        label: "County",
        error: errors.county?.message,
        disabled: watch("polygons")?.length > 0,
        // Remove all of these "as CountyOption" once we have strictNullChecks enabled in this project
        selectedOption: watch("county") as CountyOption,
        initialOptions: (watch("county") as CountyOption)
            ? [watch("county") as CountyOption]
            : [],
        onSelect: async (selectedItem) => {
            if (!selectedItem) {
                setValue("county", null);
                setSearchResult(null);
                subdivisionAutocompleteProps.clear();
                return;
            }

            const featureColl = await fetchMapboxFeature(selectedItem.id);
            const feature = featureColl.features[0];
            if (feature) {
                const result = await findFeatureOnMap(map, feature, 400);

                if (result && result.mapFeature) {
                    const { identifyLayer, idField, mapFeature } = result;

                    // Filter layer by feature ID
                    const newFilter = ["==", idField, mapFeature.id];
                    const inverseFilter = ["!", newFilter];

                    // Apply map filter for shadow layer
                    setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });
                    selectedItem.id = mapFeature.properties.fips;
                    selectedItem.data.id = mapFeature.properties.fips;
                    setValue("county", selectedItem);
                    trigger("county"); // Trigger validation
                }
            }
        },
        onSearch: async (inputValue) => {
            if (!inputValue) return [];
            const data = await searchMapbox(inputValue, "district");
            return (
                data?.map((e) => {
                    const formattedName = `${e.name}, ${e.context.region.region_code}`;
                    return {
                        id: e.mapbox_id,
                        label: formattedName,
                        inputValue: formattedName,
                        data: {
                            id: e.mapbox_id,
                            label: formattedName,
                            county: e.name,
                            state: e.context.region.region_code,
                        },
                    };
                }) ?? []
            );
        },
        onReset: () => {
            subdivisionAutocompleteProps.onReset();
        },
    });

    const subdivisionAutocompleteProps = useAutocomplete<MapboxSuggestion>({
        id: "subdivision-search-autocomplete",
        placeholder: "Enter subdivision name...",
        label: "Subdivision",
        onSelect: async (selectedItem) => {
            if (selectedItem?.label) {
                setValue("subdivisionList", [...subdivisionList, selectedItem.label]);
                subdivisionAutocompleteProps.clear();
            }
        },
        onSearch: async (inputValue) => {
            const county = watch("county");

            const params = {
                county: county?.id,
                field: "SubdivisionName",
                query: inputValue,
            };
            const queryString = new URLSearchParams(params).toString();
            const result: AutocompleteResult = await fetch(
                `/api/property/autocomplete/?${queryString}`,
            );

            return result.data
                .map((e) => {
                    return {
                        id: e,
                        label: e,
                        inputValue: e,
                    };
                })
                .filter((e) => !subdivisionList.includes(e.label));
        },
        disabled: !getValues().county,
    });

    const showExcludeZip = watch("showExcludeZip");
    const includedZipCodes = watch("includedZipCodes", []);
    const excludeZipCodes = watch("excludeZipCodes", []);
    const subdivisions = watch("subdivisionList", []);
    const subdivisionList = watch("subdivisionList", []);

    const isError = hasErrorByKeys(errors, [
        "county",
        "zipCode",
        "excludeZipCode",
        "acresFrom",
        "acresTo",
        "improvementPercentage",
    ]);
    const excludeZipCodeRegister = register("excludeZipCode");

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Parcel" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Autocomplete {...countyAutocompleteProps} autoFocus />

                <div>
                    <Autocomplete {...subdivisionAutocompleteProps} />
                    <TagList
                        items={subdivisions}
                        onRemove={(item) => {
                            setValue(
                                "subdivisionList",
                                subdivisionList.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="zipCode"
                        placeholder="Search ZIP..."
                        label="Include ZIPs"
                        action={
                            showExcludeZip ? null : (
                                <Button
                                    variant="base"
                                    onClick={() => {
                                        setValue("showExcludeZip", true);
                                    }}
                                >
                                    <Typography
                                        variant="span"
                                        color="primary-500"
                                        weight="medium"
                                    >
                                        Exclude ZIPs
                                    </Typography>
                                </Button>
                            )
                        }
                        error={errors.zipCode?.message.toString()}
                        onEnter={async (value) => {
                            const isValid = await trigger("zipCode");
                            if (!isValid || !value) return;
                            if (includedZipCodes.includes(value)) {
                                // Don't add duplicates
                                setValue("zipCode", null);
                            } else {
                                // Add the zip code to the list
                                setValue("includedZipCodes", [
                                    ...includedZipCodes,
                                    value,
                                ]);
                                setValue("zipCode", null);
                            }
                        }}
                        {...register("zipCode")}
                    />

                    <TagList
                        items={includedZipCodes}
                        onRemove={(item) => {
                            setValue(
                                "includedZipCodes",
                                includedZipCodes.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                {showExcludeZip && (
                    <div>
                        <Input
                            id="excludeZipCode"
                            placeholder="Search ZIP..."
                            label="Exclude ZIPs"
                            error={errors.excludeZipCode?.message.toString()}
                            onEnter={async (value) => {
                                const isValid = await trigger("excludeZipCode");
                                if (!isValid || !value) return;
                                if (excludeZipCodes.includes(value)) {
                                    // Don't add duplicates
                                    setValue("excludeZipCode", null);
                                } else {
                                    // Add the zip code to the list
                                    setValue("excludeZipCodes", [
                                        ...excludeZipCodes,
                                        value,
                                    ]);
                                    setValue("excludeZipCode", null);
                                }
                            }}
                            // Autofocus as soon as it shows up
                            autoFocus
                            {...excludeZipCodeRegister}
                        />

                        <TagList
                            items={excludeZipCodes}
                            onRemove={(item) => {
                                setValue(
                                    "excludeZipCodes",
                                    excludeZipCodes.filter((i) => i !== item),
                                );
                            }}
                        />
                    </div>
                )}
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="acresFrom"
                        label={fieldKeyToTitle.acresFrom}
                        placeholder="From"
                        error={errors.acresFrom?.message}
                        {...register("acresFrom", {
                            deps: ["acresTo"],
                        })}
                    />
                    <Input
                        id="acresTo"
                        placeholder="To"
                        srLabel="Acres To"
                        error={errors.acresTo?.message}
                        {...register("acresTo", {
                            deps: ["acresFrom"],
                        })}
                    />
                </div>

                <Input
                    id="improvementPercentage"
                    label={fieldKeyToTitle.improvementPercentage}
                    placeholder="%"
                    error={errors.improvementPercentage?.message}
                    {...register("improvementPercentage")}
                />
            </div>
        </Accordion>
    );
}

function StructureFormAccordion() {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);

    const isError = Boolean(
        errors.minTotalSqFt?.message ||
            errors.minTotalStructureCount?.message ||
            errors.maxTotalSqFt?.message ||
            errors.maxTotalStructureCount?.message ||
            errors.aiVacant?.message,
    );

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Structure" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-gap-6">
                    <Toggle
                        id="aiVacant"
                        labelClassName={"lui-typography-medium"}
                        label={fieldKeyToTitle.aiVacant}
                        info="Remove parcels that have AI detected buildings."
                        {...register("aiVacant")}
                    />
                </div>
                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.totalSqFt}
                    </Typography>

                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="totalSqFt"
                            error={errors.minTotalSqFt?.message}
                            placeholder="Min"
                            info="County assessor data"
                            {...register("minTotalSqFt")}
                        />

                        <Input
                            id="totalSqFt"
                            error={errors.maxTotalSqFt?.message}
                            placeholder="Max"
                            {...register("maxTotalSqFt")}
                        />
                    </div>
                </div>
                <div className="lui-flex lui-gap-6">
                    <Input
                        id="totalStructureCount"
                        label={fieldKeyToTitle.totalStructureCount}
                        error={errors.minTotalStructureCount?.message}
                        placeholder="Min"
                        info="County assessor data"
                        {...register("minTotalStructureCount")}
                    />
                    <Input
                        id="totalStructureCount"
                        srLabel={fieldKeyToTitle.maxTotalStructureCount}
                        error={errors.maxTotalStructureCount?.message}
                        placeholder="Max"
                        {...register("maxTotalStructureCount")}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function OwnerFormAccordion() {
    const {
        register,
        formState: { errors },
        setValue,
        trigger,
        watch,
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const excludeKeywords = watch("excludeKeywords", []).map((x) => x.toUpperCase());
    const includeKeywords = watch("includeKeywords", []).map((x) => x.toUpperCase());

    const isError = hasErrorByKeys(errors, [
        "outOfStateOwner",
        "outOfCountyOwner",
        "outOfZipOwner",
        "minOwnershipLengthInMonths",
        "maxOwnershipLengthInMonths",
        "excludeKeyword",
        "includeKeyword",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Owner" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="outOfStateOwner"
                    label={fieldKeyToTitle.outOfStateOwner}
                    {...register("outOfStateOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfCountyOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfCountyOwner"
                    label={fieldKeyToTitle.outOfCountyOwner}
                    {...register("outOfCountyOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfZipOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="outOfZipOwner"
                    label={fieldKeyToTitle.outOfZipOwner}
                    {...register("outOfZipOwner", {
                        onChange: (e) => {
                            if (e.target.checked) {
                                setValue("outOfStateOwner", false);
                                setValue("outOfCountyOwner", false);
                            }
                        },
                    })}
                />
                <Toggle
                    id="excludeCorpOwner"
                    label={fieldKeyToTitle.excludeCorpOwner}
                    {...register("excludeCorpOwner")}
                />
                <Toggle
                    id="interFamilyFlag"
                    label={fieldKeyToTitle.interFamilyFlag}
                    {...register("interFamilyFlag")}
                />

                <div>
                    <Typography variant="span" weight="medium">
                        {fieldKeyToTitle.ownershipLengthInMonths}{" "}
                        <Typography variant="span" color="gray-700">
                            (Months)
                        </Typography>
                    </Typography>
                    <div className="lui-flex lui-gap-6 lui-mt-1">
                        <Input
                            id="minOwnershipLengthInMonths"
                            placeholder="Min"
                            {...register("minOwnershipLengthInMonths")}
                        />
                        <Input
                            id="maxOwnershipLengthInMonths"
                            placeholder="Max"
                            {...register("maxOwnershipLengthInMonths")}
                        />
                    </div>
                </div>

                <div>
                    <Input
                        id="includeKeywords"
                        placeholder="Include keywords..."
                        label="Only Include Keywords"
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("includeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!includeKeywords.includes(keyword)) {
                                    includeKeywords.push(keyword);
                                }
                            });
                            setValue("includeKeywords", includeKeywords);
                            setValue("includeKeyword", null);
                        }}
                        {...register("includeKeyword")}
                    />

                    <TagList
                        items={includeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "includeKeywords",
                                includeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>

                <div>
                    <Input
                        id="excludeKeywords"
                        placeholder="Exclude keywords..."
                        label="Exclude Keywords"
                        error={errors.excludeKeyword?.message.toString()}
                        onEnter={async (value) => {
                            value = value.trim();
                            const isValid = await trigger("excludeKeyword");
                            if (!isValid || !value) return;
                            value.split(",").forEach((keyword) => {
                                keyword = keyword.trim().toUpperCase();
                                if (!excludeKeywords.includes(keyword)) {
                                    excludeKeywords.push(keyword);
                                }
                            });
                            setValue("excludeKeywords", excludeKeywords);
                            setValue("excludeKeyword", null);
                        }}
                        {...register("excludeKeyword")}
                    />

                    <TagList
                        items={excludeKeywords}
                        onRemove={(item) => {
                            setValue(
                                "excludeKeywords",
                                excludeKeywords.filter((i) => i !== item),
                            );
                        }}
                    />
                </div>
            </div>
        </Accordion>
    );
}

function ScrubbingFormAccordion() {
    const { user } = useContext(UserContext);
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const [isOpened, setIsOpened] = useState(false);
    const isError = hasErrorByKeys(errors, [
        "removeLandLockedParcels",
        "removeDuplicateParcels",
        "deduplicatingType",
        "maxWetlandCoverage",
        "maxFloodCoverage",
        "minRoadFrontage",
    ]);
    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Scrubbing" isError={isError} />}
        >
            <div className="lui-flex lui-flex-col lui-gap-6">
                <Toggle
                    id="excludeHOA"
                    label={fieldKeyToTitle.excludeHOA}
                    {...register("excludeHOA")}
                />
                <Toggle
                    label={fieldKeyToTitle.removeLandLockedParcels}
                    {...register("removeLandLockedParcels")}
                />
                <Toggle
                    label={fieldKeyToTitle.removeDuplicateParcels}
                    {...register("removeDuplicateParcels")}
                />

                <SelectionGroupFormController
                    control={control}
                    label={fieldKeyToTitle.deduplicatingType}
                    name="deduplicatingType"
                    options={[
                        { title: "Smaller Parcel", id: "smaller" },
                        { title: "Larger Parcel", id: "larger" },
                    ]}
                    horizontal
                />
                <Input
                    id="maxWetlandCoverage"
                    label={fieldKeyToTitle.maxWetlandCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxWetlandCoverage?.message}
                    {...register("maxWetlandCoverage")}
                />
                <Input
                    id="maxFloodCoverage"
                    label={fieldKeyToTitle.maxFloodCoverage}
                    placeholder="%"
                    info="2 credits / record. Empty % will not scrub."
                    error={errors.maxFloodCoverage?.message}
                    {...register("maxFloodCoverage")}
                />
                {user?.is_staff ? (
                    <Input
                        id="minRoadFrontage"
                        label={
                            <>
                                {fieldKeyToTitle.minRoadFrontage}{" "}
                                <Badge variant="info">STAFF ONLY</Badge>
                            </>
                        }
                        placeholder=""
                        info="Minimum Road Frontage in feet."
                        error={errors.minRoadFrontage?.message}
                        {...register("minRoadFrontage")}
                    />
                ) : (
                    <Typography className="lui-flex lui-gap-2" weight="medium">
                        Road Frontage
                        <Badge variant="danger">COMING SOON</Badge>
                    </Typography>
                )}
            </div>
        </Accordion>
    );
}

function SkipTracingFormAccordion() {
    const [isOpened, setIsOpened] = useState(false);
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext<FormSchemaType>();
    const isError = hasErrorByKeys(errors, ["skipTracingType"]);

    return (
        <Accordion
            isOpen={isOpened}
            onToggle={() => setIsOpened(!isOpened)}
            title={<AccordionFormTitle title="Skip Tracing" isError={isError} />}
        >
            <SelectionGroupFormController
                control={control}
                label={fieldKeyToTitle.skipTracingType}
                name="skipTracingType"
                options={[
                    {
                        id: "", // The backend has an empty string as the default value
                        title: "No Skipping",
                        subtitle: "FREE",
                    },
                    {
                        id: "standard",
                        title: "Standard",
                        subtitle: "5 Credits / Hit",
                        info: "Includes up to 6 phone numbers and the type of each phone number (mobile, landline, VOIP).",
                    },
                    {
                        id: "premium",
                        title: "Premium",
                        subtitle: "6 Credits / Hit",
                        info: "Includes up to 7 phone numbers, the type of each phone number (mobile, landline, VOIP), age of owner, owner email, relative phone numbers and emails, and higher hit rate (usually > 95%).",
                        children: (
                            <div className="lui-flex lui-justify-between lui-items-center lui-w-full">
                                <div>
                                    <Typography weight="medium" size="xs">
                                        Scrub DNC
                                    </Typography>
                                    <Typography size="xs" color="gray-700">
                                        Remove Do Not Contact. 1 credit / record.
                                    </Typography>
                                </div>
                                <div>
                                    <Toggle {...register("isScrubDnc")} />
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </Accordion>
    );
}

function AccordionFormTitle({ title, isError }: { title: string; isError?: boolean }) {
    return (
        <div className="lui-flex lui-items-center lui-gap-1.5">
            <Typography size="lg" weight="bold">
                {title}
            </Typography>
            {isError && (
                <Icon
                    name="InfoCircle"
                    size="sm"
                    color="red-700"
                    className="lui-mr-1"
                />
            )}
        </div>
    );
}

/**
 * Check if there is an error in the form state by list of keys
 * Used to check the section so we can identify which accordion has an error
 */
function hasErrorByKeys(errors: Record<string, any>, keys: string[]): boolean {
    return keys.some((key) => get(errors, `${key}.message`));
}

//  Map field key to title
const fieldKeyToTitle = {
    county: "County",
    zipCode: "ZIP Code",
    excludeZipCode: "Exclude ZIP Code",
    acresFrom: "Acres Range",
    acresTo: "Acres To",
    improvementPercentage: "Max Improvement Percentage",
    ownershipLengthInMonths: "Ownership Length",
    minOwnershipLengthInMonths: "Min Ownership Length",
    maxOwnershipLengthInMonths: "Max Ownership Length",
    aiVacant: "Vacant Land Only (AI)",
    totalSqFt: "Total Structure Square Feet",
    minTotalSqFt: "Min Total Structure Footprint",
    maxTotalSqFt: "Max Total Structure Footprint",
    totalStructureCount: "Total Structure Count",
    minTotalStructureCount: "Min Structure Count",
    maxTotalStructureCount: "Max Structure Count",
    outOfStateOwner: "Out of State Owner",
    outOfCountyOwner: "Out of County Owner",
    outOfZipOwner: "Out of ZIP Owner",
    excludeCorpOwner: "Exclude Corporate Owners",
    interFamilyFlag: "Only Inter-Family Transfers",
    excludeHOA: "Remove HOA Parcels",
    removeLandLockedParcels: "Remove Land Locked Parcels",
    removeDuplicateParcels: "Deduplicate Owners",
    deduplicatingType: "Keep Which Parcel When Deduplicating",
    maxWetlandCoverage: "Max Wetland Coverage",
    maxFloodCoverage: "Max Flood Coverage",
    minRoadFrontage: "Road Frontage",
    skipTracingType: "Skip Tracing Type",
};

function numberOrNull(val?: number) {
    return Boolean(val) ? toNumber(val) : null;
}
