import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useWalletApi } from "@src/hooks/useWalletApi";
import { Button } from "@src/land_ui/button/button";
import { Input } from "@src/land_ui/input/input";
import { Modal } from "@src/land_ui/modal/modal";
import {
    type APIError,
    type ParcelExportLandScrub,
    type ParcelExportSkipTrace,
    type ParcelsListParams,
} from "@src/orval/gen/model";
import { type ParcelExportPriceBreakdown } from "@src/pages/parcel_viewer/types";
import { PurchaseCreditsModal } from "./purchase_credits";
import { useExportsPurchaseCreate } from "@src/orval/gen/api";
import { useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";

export interface ExportsCalculatePriceRequest {
    name: string;
    skip_trace: ParcelExportSkipTrace;
    land_scrub: ParcelExportLandScrub;
    search_filters: ParcelsListParams;
}
interface PurchaseExportProps {
    details: ParcelExportPriceBreakdown;
    payloadRequest: ExportsCalculatePriceRequest;
    setActivePurchaseExport: (value: string) => void;
}
export function PurchaseExport({
    details,
    payloadRequest,
    setActivePurchaseExport,
}: PurchaseExportProps) {
    const purchaseButtonRef = useRef(null);
    const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
    const [isExportNameModalOpen, setIsExportNameModalOpen] = useState(false);
    const { wallet } = useWalletApi({ enabled: false });
    const [newName, setName] = useState(payloadRequest.name);
    const queryClient = useQueryClient();

    const { mutate: purchaseExportApi, isPending: isPurchaseExportLoading } =
        useExportsPurchaseCreate({
            mutation: {
                onSuccess: (purchaseExportData) => {
                    // Invalidate export list since we have new export now
                    queryClient.invalidateQueries({
                        queryKey: ["exportList"],
                    });
                    setActivePurchaseExport(purchaseExportData.id);
                },
                onError: (error: AxiosError) => {
                    const data = error.response?.data as APIError;
                    if (data.code === "insufficient_funds") {
                        setIsPurchaseOpen(true);
                    } else {
                        throw error;
                    }
                },
            },
        });

    const callPurchaseExport = (name?: string) => {
        purchaseExportApi({
            data: {
                ...payloadRequest,
                name: name || newName,
            },
        });
    };

    return (
        <>
            <Button
                ref={purchaseButtonRef}
                isLoading={isPurchaseExportLoading}
                disabled={isPurchaseExportLoading}
                onClick={async () => {
                    setIsExportNameModalOpen(true);
                }}
            >
                Purchase Now
            </Button>

            {isExportNameModalOpen && (
                <ExportNameModal
                    exportName={payloadRequest.name}
                    isOpen={isExportNameModalOpen}
                    setIsOpen={(value) => {
                        setIsExportNameModalOpen(value);
                        if (!value) {
                            purchaseButtonRef.current?.focus();
                        }
                    }}
                    onSuccess={async (name) => {
                        setName(name);
                        setIsExportNameModalOpen(false);
                        callPurchaseExport(name);
                    }}
                />
            )}

            {isPurchaseOpen && (
                <PurchaseCreditsModal
                    onError={() => {}}
                    onSuccess={() => {
                        callPurchaseExport();
                    }}
                    minCredits={
                        details.total_price -
                        (wallet?.balance?.cash + wallet?.balance?.gift)
                    }
                    isOpen={isPurchaseOpen}
                    setIsOpen={(value) => {
                        setIsPurchaseOpen(value);

                        if (!value) {
                            purchaseButtonRef.current?.focus();
                        }
                    }}
                />
            )}
        </>
    );
}

function ExportNameModal({
    exportName,
    isOpen,
    setIsOpen,
    onSuccess,
}: {
    exportName: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onSuccess: (exportName: string) => void;
}) {
    const schema = z.object({
        name: z.string(),
    });

    type FormData = z.infer<typeof schema>;
    const { register, handleSubmit } = useForm<FormData>({
        defaultValues: {
            name: exportName,
        },
    });
    const onSubmit = (data: FormData) => {
        onSuccess(data.name);
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header preventAutoFocus>Name Your Export</Modal.Header>
                <Modal.Content className="lui-p-6">
                    <Input
                        type="text"
                        name="name"
                        label="Export Name"
                        placeholder="Enter export name"
                        autoFocus
                        autoSelectionOnFocus
                        {...register("name")}
                    />
                </Modal.Content>

                <Modal.Footer className="lui-flex lui-justify-end">
                    <Button
                        variant="inline"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className="lui-px-5">
                        Continue
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
