import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { formatMoneyCents, formatNumber } from "@src/functions";
import { Badge } from "@src/land_ui/badge/badge";
import { Button } from "@src/land_ui/button/button";
import { Input } from "@src/land_ui/input/input";
import { Modal } from "@src/land_ui/modal/modal";
import { Typography } from "@src/land_ui/typography/typography";
import { useState } from "react";
import { AXIOS_INSTANCE } from "@src/orval/axios-instance";
import { useWalletApi } from "@src/hooks/useWalletApi";
import { useMutation } from "@tanstack/react-query";
import { Icon } from "@src/land_ui/icon/icon";
import { isNumber } from "lodash";

interface PurchaseResponse {
    created_at: string;
    credit: number;
    credit_type: "cash";
    debit: number;
    description: string;
    id: string;
    paid_amount: number;
}

interface PurchaseRequestBody {
    amount: number;
}

interface PurchaseCreditsModalProps {
    minCredits: number;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onSuccess: () => void;
    onError: (error: string) => void;
}

export function PurchaseCreditsModal({
    minCredits,
    isOpen,
    setIsOpen,
    onSuccess,
    onError,
}: PurchaseCreditsModalProps) {
    const { wallet, fetchWallet } = useWalletApi({ enabled: isOpen });
    const [failureCount, setFailureCount] = useState(0);
    const {
        mutate: purchaseCreditsApi,
        isPending,
        error,
        data,
    } = useMutation({
        onSuccess: () => {
            setFailureCount(0);
            fetchWallet();
        },
        onError: () => {
            setFailureCount((prev) => prev + 1);
        },
        mutationFn: (purchaseRequest: PurchaseRequestBody) =>
            AXIOS_INSTANCE.post<PurchaseResponse, { amount: number }>(
                "/user/user/wallet/",
                purchaseRequest,
            ),
    });

    const schema = z.object({
        purchaseAmount: z.coerce
            .number({ message: "Please enter a valid number" })
            .gte(minCredits, {
                message: `Please enter a number thats over the minimum required ${formatNumber(
                    minCredits,
                )} credits`,
            }),
    });

    type SchemaType = z.infer<typeof schema>;

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<SchemaType>({
        mode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {
            purchaseAmount: null,
        },
    });

    const onSubmit = async (formData: SchemaType) => {
        purchaseCreditsApi({
            amount: formData.purchaseAmount,
        });
    };

    const purchaseAmountValue = watch("purchaseAmount");
    const amountValue = Math.max(0, Math.min(purchaseAmountValue, 1000000));

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Show the form if no error or issues */}
                {failureCount === 0 && !data && (
                    <>
                        <Modal.Header preventAutoFocus>
                            <Typography weight="bold" size="xl">
                                Purchase credits
                            </Typography>
                        </Modal.Header>

                        <Modal.Content className="lui-p-5">
                            <Typography>
                                This action requires{" "}
                                <Typography variant="span" weight="medium">
                                    {formatNumber(minCredits)}
                                </Typography>{" "}
                                additional credits. Your current balance is{" "}
                                <Typography variant="span" weight="medium">
                                    {formatNumber(wallet?.balance?.cash ?? 0)}
                                </Typography>{" "}
                                credits. Please top up to proceed.
                            </Typography>
                            <div className="lui-mt-5">
                                <Input
                                    autoFocus
                                    action={
                                        isNumber(amountValue) && amountValue > 0
                                            ? formatMoneyCents(amountValue)
                                            : ""
                                    }
                                    endAdornment={
                                        <Typography weight="medium">Credits</Typography>
                                    }
                                    label="Credits"
                                    error={errors.purchaseAmount?.message}
                                    {...register("purchaseAmount", {
                                        onChange: (e) =>
                                            setValue(
                                                "purchaseAmount",
                                                e.target.value.replaceAll(",", ""),
                                            ),
                                    })}
                                />
                                <div className="lui-flex lui-gap-2 lui-mt-2">
                                    {[75_000, 100_000, 200_000].map((e) => {
                                        return (
                                            <Button
                                                key={`credit-options-${e}`}
                                                variant="base"
                                                onClick={() => {
                                                    setValue("purchaseAmount", e, {
                                                        shouldValidate: true,
                                                    });
                                                }}
                                            >
                                                <Badge
                                                    variant="info"
                                                    className="lui-cursor-pointer lui-rounded-lg"
                                                    size="sm"
                                                >
                                                    {formatNumber(e)} credits
                                                </Badge>
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>

                            <Typography className="lui-mt-6">
                                Your balance after recharge will be{" "}
                                <Typography variant="span" weight="medium">
                                    {formatNumber(
                                        wallet?.balance?.cash + purchaseAmountValue,
                                    )}
                                </Typography>{" "}
                                credits.
                            </Typography>

                            <Typography color="gray-700" size="xs" className="lui-mt-6">
                                Minimum purchase order of 100 credits. Credits cannot be
                                refunded, exchanged or sold.
                            </Typography>
                        </Modal.Content>

                        <Modal.Footer className="lui-flex lui-justify-end">
                            <Button variant="inline" onClick={() => setIsOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                isLoading={isPending}
                                disabled={
                                    Boolean(errors?.purchaseAmount?.message) ||
                                    purchaseAmountValue < 100 ||
                                    isPending
                                }
                                variant="primary"
                                type="submit"
                                className="lui-px-5"
                            >
                                Purchase
                            </Button>
                        </Modal.Footer>
                    </>
                )}

                {failureCount > 0 && (
                    <>
                        <Modal.Header>
                            <div className="lui-flex lui-gap-3 lui-items-center">
                                <Icon name="InfoCircle" color="orange-700" />
                                Purchase failed
                            </div>
                        </Modal.Header>
                        <Modal.Content className="lui-p-5">
                            <Typography>
                                We couldn’t update your balance. Your have not been
                                charged. Please try again or{" "}
                                <Button
                                    variant="base"
                                    href="mailto:hello@landinsights.co"
                                >
                                    contact us
                                </Button>
                            </Typography>
                        </Modal.Content>
                        <Modal.Footer className="lui-flex lui-justify-end">
                            <Button
                                variant="inline"
                                onClick={() => {
                                    setIsOpen(false);
                                    onError?.(error.message);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                isLoading={isPending}
                                disabled={failureCount > 3 || isPending}
                                variant="primary"
                                type="submit"
                                className="lui-px-5"
                            >
                                Retry
                            </Button>
                        </Modal.Footer>
                    </>
                )}
                {/* Success Modal  */}
                {Boolean(data) && (
                    <>
                        <Modal.Header>Purchase completed</Modal.Header>
                        <Modal.Content className="lui-p-5">
                            <Typography>
                                Top up completed successfully. Your new balance is{" "}
                                <Typography variant="span" weight="medium">
                                    {formatNumber(wallet?.balance?.cash)}
                                </Typography>{" "}
                                credits. You can now proceed.
                            </Typography>
                        </Modal.Content>
                        <Modal.Footer className="lui-flex lui-justify-end">
                            <Button
                                variant="primary"
                                className="lui-px-5"
                                onClick={() => {
                                    setIsOpen(false);
                                    onSuccess?.();
                                }}
                            >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </form>
        </Modal>
    );
}
