import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";

export type UseQueryParamReturnType<T> = [
    value: T,
    setValue: (value: T) => void,
    removeValue: () => void,
] & {
    value: T;
    setValue: (value: T) => void;
    removeValue: () => void;
};

/**
 * Hook to manage query parameters in the URL
 * @param key
 * @param defaultValue
 * @returns [value, setValue, removeValue] or {value, setValue, removeValue}
 * @example
 * const {value, setValue, removeValue} = useQueryParam("parcelId", 123);
 * Or array destructuring, making it easy to customize the field names
 * const [filter, setFilter, removeFilter] = useQueryParam("filter", "all");
 */
export function useQueryParam<T>(
    key: string,
    defaultValue: T,
): UseQueryParamReturnType<T> {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (defaultValue && !searchParams.get(key)) {
            setValue(defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const setValue = useCallback(
        (value: T) => {
            const params = new URLSearchParams(window.location.search);
            params.set(key, JSON.stringify(value) as string);
            setSearchParams(params);
            navigate(
                {
                    search: params.toString(),
                },
                { replace: true },
            );
        },
        [key, navigate, setSearchParams],
    );

    const removeValue = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        params.delete(key);
        setSearchParams(params);
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [key, location.pathname, navigate, setSearchParams]);

    function getValue() {
        try {
            return JSON.parse(searchParams.get(key)) as T;
        } catch {
            return searchParams.get(key);
        }
    }

    const result = [getValue(), setValue, removeValue] as UseQueryParamReturnType<T>;

    result.value = result[0];
    result.setValue = result[1];
    result.removeValue = result[2];

    return result;
}
