import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { fetch, parseErrorResponse } from "functions";
import { Loading } from "library";
import { STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY } from "settings";

export default function RegisterSubscription() {
    const [user, setUser] = useState();
    const navigate = useNavigate();

    // Load user on page load and refresh every 10 seconds
    useEffect(() => {
        let timeoutID;

        async function fetchUser() {
            try {
                const user = await fetch("/user/user/");
                setUser(user);

                // Redirect home if already subscribed to Stripe
                if (user.onboarding_stripe_completed) {
                    return navigate("/home");
                }

                timeoutID = setTimeout(fetchUser, 1000 * 10);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        }

        fetchUser();

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, [setUser, navigate]);

    if (!user) {
        return <Loading />;
    }

    return (
        <div className="card">
            <div className="card-body text-center">
                <stripe-pricing-table
                    pricing-table-id={STRIPE_PRICING_TABLE_ID}
                    publishable-key={STRIPE_PUBLISHABLE_KEY}
                    customer-email={user.email}
                ></stripe-pricing-table>
            </div>
        </div>
    );
}
