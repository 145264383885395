import type { CountyOption } from "@src/pages/parcel_viewer/types";

import MapIconButton from "@src/pages/parcel_viewer/ui/map_button";
import ReactMapControl from "@src/pages/parcel_viewer/ui/map_control";

interface FilterControlProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export default function FilterControl({ open, setOpen }: FilterControlProps) {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton
                icon="fa-solid fa-filter"
                title="Filter"
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

export function generateExportListName(countyOption: CountyOption) {
    if (!countyOption) {
        return "";
    }
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${countyOption.county || ""} ${
        countyOption.state || ""
    } ${year}-${month}-${day}`.trim();
}

export function generateSaveListTitle(countyOption: CountyOption) {
    if (!countyOption) {
        return "";
    }
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${countyOption.county}, ${countyOption.state} - ${year}.${month}.${day}`;
}
