import React, { Component } from "react";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";

import { USER_EXISTS } from "constants";
import { GOOGLE_CLIENT_ID } from "settings";
import { Form, TextInput, Card, Button } from "library";
import { ajax_wrapper, save_token, isLoggedIn } from "functions";
import { withNavigate } from "hooks";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: null,
            error: "",
            receive_response: true,
        };

        this.signup = this.signup.bind(this);
        this.google_signup = this.google_signup.bind(this);
        this.google_error = this.google_error.bind(this);
        this.signup_callback = this.signup_callback.bind(this);
        this.showErrorModal = this.showErrorModal.bind(this);
    }

    componentDidMount() {
        if (isLoggedIn()) {
            // Use setTimeout because you can't directly call navigate in
            // componentDidMount
            setTimeout(() => this.props.navigate("/home"));
            return;
        }

        const client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            callback: (response) => this.google_signup(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client });
    }

    signup(state) {
        const data = {
            first_name: state["first_name"],
            last_name: state["last_name"],
            email: state["email"],
            password: state["password"],
            company_name: state["company_name"],
        };

        ajax_wrapper("POST", "/user/register/", data, this.signup_callback);
        this.setState({ receive_response: false });
    }

    google_signup(state) {
        if (state && state.access_token) {
            ajax_wrapper("POST", "/user/google_login/", state, this.signup_callback);
        }
    }

    google_error(state) {
        console.log("Google Error", state);
    }

    signup_callback(value) {
        console.log(value);
        // Sample errors:
        // {"error":"Bad Request","code":400,"data":{"error":"Company Name field is missing"}}
        // {"error":"Bad Request","code":400,"data":{"error":"All Seats Taken"}}
        // {"error":"Internal Server Error","code": 500,"data":{"message": "A user with that email already exists.", "code": "error"}}
        if (value.error) {
            if (value.data && value.data.code && value.data.code === USER_EXISTS) {
                this.showErrorModal(
                    'Oh! It looks like you already have an account, you should <a href="/login">sign in</a> and resume your onboarding',
                );
            } else {
                this.showErrorModal(value.data.error);
            }
        } else {
            save_token(value);
            this.props.navigate("/create-account/subscription");
        }
    }

    showErrorModal(html) {
        Swal.fire({
            title: "Warning",
            html,
            icon: "error",
            confirmButtonText: "OK",
        }).then(() => this.setState({ receive_response: true }));
    }

    render() {
        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ width: "100%", border: "solid 1px #e9ecef" }}
                >
                    <div
                        style={{
                            marginRight: "10px",
                            display: "inline-block",
                            lineHeight: "20px",
                        }}
                    >
                        {"Signup with Google"}
                    </div>
                    <img
                        style={{
                            width: "20px",
                            display: "inline-block",
                            verticalAlign: "top",
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                    />
                </Button>
            );
        }

        return (
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <Card>
                    <div className="card-header text-center pt-4">
                        <h5>Sign up</h5>
                    </div>
                    <div style={{ padding: "0 1.5rem" }}>
                        {google_button}
                        <div className="mt-2 position-relative text-center">
                            <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white  px-3">
                                or
                            </p>
                        </div>
                    </div>
                    <div className="card-body">
                        <Form
                            submit={this.signup}
                            submit_button_type="gradient-info"
                            submit_button_class={"w-100 my-4 mb-2"}
                            submit_text={"SIGN UP"}
                            receive={this.state.receive_response}
                        >
                            <TextInput name="first_name" placeholder="First Name" />
                            <TextInput name="last_name" placeholder="Last Name" />
                            <TextInput name="email" placeholder="Email" />
                            <TextInput
                                name="phone_number"
                                placeholder="Phone Number"
                                required={true}
                            />
                            <TextInput name="company_name" placeholder="Company Name" />
                            <TextInput
                                type="password"
                                name="password"
                                placeholder="Password"
                            />
                        </Form>
                        <p className="text-sm mt-3 mb-0">
                            Already have an account?{" "}
                            <Link className="text-dark font-weight-bolder" to="/login">
                                Sign in
                            </Link>
                        </p>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withNavigate(Register);
