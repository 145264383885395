import { useParams } from "react-router-dom";
import { useCompsList, useParcelsRetrieve } from "@src/orval/gen/api.js";

export default function Comping() {
    const { parcelId } = useParams();
    console.log(parcelId);
    const { data: parcel } = useParcelsRetrieve(parseInt(parcelId, 10));
    console.log("parcel", parcel);
    const { data: compsData } = useCompsList(
        {
            point: parcel?.point ? [...parcel.point] : [],
            dist: 2000,
            status: ["SOLD", "ACTIVE", "PENDING"],
            acres_min: 1,
            acres_max: 20,
        },
        {
            query: {
                enabled: Boolean(parcel),
            },
        },
    );
    console.log("compsData", compsData);
    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Comping Tool</h5>
            </div>
            <div className="card-body">
                <strong>Coming Soon</strong>
            </div>
        </div>
    );
}
