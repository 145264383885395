import { useState } from "react";
import { formatNumber, pluralize } from "@src/functions";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { Modal, useModal } from "@src/land_ui/modal/modal";
import { Typography } from "@src/land_ui/typography/typography";
import { type ParcelExportPriceBreakdown } from "@src/pages/parcel_viewer/types";
import { isNumber } from "lodash";

interface SummaryExportPriceDetailsProps {
    details: ParcelExportPriceBreakdown;
}
export function SummaryExportPriceDetails({ details }: SummaryExportPriceDetailsProps) {
    if (!details) {
        return null;
    }
    return (
        <div>
            <div className="lui-flex lui-flex-col lui-gap-2 lui-p-6">
                <div className="lui-flex lui-justify-between">
                    <Typography weight="bold">Total Credits</Typography>
                    <Typography weight="bold">
                        {formatNumber(details?.total_price)}
                    </Typography>
                </div>
                <LightRowDetail
                    label={`Records ∙ ${getPerRecordLabel(
                        details.parcel_export_price.price_per_row,
                    )}`}
                    credits={details?.parcel_export_price.total_price}
                />
                {details?.land_scrub_price && (
                    <LightRowDetail
                        label="Scrubbing"
                        credits={details?.land_scrub_price?.total_price}
                    />
                )}

                {details?.skip_trace_price && (
                    <LightRowDetail
                        label="Skipping"
                        credits={details?.skip_trace_price?.total_price}
                    />
                )}
                <CostBreakdownModal
                    details={details}
                    trigger={
                        <Button variant="base">
                            <Typography
                                variant="span"
                                weight="medium"
                                color="primary-500"
                                className="lui-mt-1 lui-flex "
                            >
                                See breakdown
                            </Typography>
                        </Button>
                    }
                />
            </div>
        </div>
    );
}

interface LightRowDetailProps {
    label: string;
    perRowCredit?: number;
    credits: number;
}
function LightRowDetail({ label, perRowCredit, credits }: LightRowDetailProps) {
    return (
        <div className="lui-flex lui-justify-between lui-w-full">
            <Typography color="gray-700" size="sm">
                {label}{" "}
                {isNumber(perRowCredit) && `∙ ${getPerRecordLabel(perRowCredit)}`}
            </Typography>
            <Typography color="gray-700" size="sm">
                {formatNumber(credits || 0)} {pluralize("credit", credits)}
            </Typography>
        </div>
    );
}

type CostBreakdownModalProps = {
    details: ParcelExportPriceBreakdown;
    trigger: React.ReactElement;
};

export function CostBreakdownModal({ details, trigger }: CostBreakdownModalProps) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} trigger={trigger}>
            <Modal.Header>Cost breakdown</Modal.Header>
            <Modal.Content className="lui-p-6 lui-flex lui-flex-col lui-gap-6">
                <div className="lui-flex lui-justify-between">
                    <div className="lui-flex lui-flex-col lui-gap-2">
                        <Typography size="lg" weight="bold">
                            Total Credits
                        </Typography>
                        <Typography color="gray-700">
                            {formatNumber(details.row_summary.total_rows)} Parcels
                        </Typography>
                    </div>
                    <div className="lui-flex lui-flex-col">
                        <Typography weight="bold" size="lg">
                            {getCreditLabel(details?.total_price)}
                        </Typography>
                    </div>
                </div>
                <Divider horizontal />
                <div className="lui-flex lui-justify-between">
                    <div className="lui-flex lui-flex-col lui-gap-2">
                        <Typography size="md" weight="bold">
                            Records
                        </Typography>
                        <Typography color="gray-700">
                            {details.parcel_export_price.price_per_row}{" "}
                            {pluralize(
                                "credit",
                                details.parcel_export_price.price_per_row,
                            )}{" "}
                            / record
                        </Typography>
                    </div>
                    <div className="lui-flex-col">
                        <Typography weight="bold" size="lg">
                            {getCreditLabel(details.parcel_export_price.total_price)}
                        </Typography>
                    </div>
                </div>

                {details.land_scrub_price && (
                    <>
                        <Divider horizontal />
                        <div className="lui-flex lui-flex-col">
                            <div className="lui-flex lui-justify-between ">
                                <Typography size="md" weight="bold">
                                    Scrubbing
                                </Typography>

                                <Typography weight="bold" size="lg">
                                    {getCreditLabel(
                                        details.land_scrub_price.total_price,
                                    )}
                                </Typography>
                            </div>
                            <div className="lui-flex lui-flex-col lui-gap-1 lui-mt-2">
                                <LightRowDetail
                                    label="Land Lock scrub"
                                    perRowCredit={
                                        details.land_scrub_price
                                            .land_locked_price_per_row
                                    }
                                    credits={details.land_scrub_price.land_locked_price}
                                />
                                <LightRowDetail
                                    label="Wetlands scrub"
                                    perRowCredit={
                                        details.land_scrub_price.wetlands_price_per_row
                                    }
                                    credits={details.land_scrub_price.wetlands_price}
                                />

                                <LightRowDetail
                                    label="Flood Zone scrub"
                                    perRowCredit={
                                        details.land_scrub_price
                                            .flood_zone_price_per_row
                                    }
                                    credits={details.land_scrub_price.flood_zone_price}
                                />
                            </div>
                        </div>
                    </>
                )}
                {details.skip_trace_price && (
                    <>
                        <Divider horizontal />
                        <div className="lui-flex lui-flex-col">
                            <div className="lui-flex lui-justify-between ">
                                <Typography size="md" weight="bold">
                                    Skipping
                                </Typography>

                                <Typography weight="bold" size="lg">
                                    {getCreditLabel(
                                        details.skip_trace_price.total_price,
                                    )}
                                </Typography>
                            </div>
                            <div className="lui-flex lui-flex-col lui-gap-1 lui-mt-2 ">
                                <LightRowDetail
                                    label={
                                        details.skip_trace_price.report_type ===
                                        "premium"
                                            ? "Premium Skipping"
                                            : "Standard Skipping"
                                    }
                                    perRowCredit={
                                        details.skip_trace_price.price_per_row
                                    }
                                    credits={details.skip_trace_price.total_price}
                                />

                                {details.skip_trace_price.scrub_dnc_price_per_row >
                                    0 && (
                                    <LightRowDetail
                                        label="Scrub DNC"
                                        perRowCredit={
                                            details.skip_trace_price
                                                .scrub_dnc_price_per_row
                                        }
                                        credits={
                                            details.skip_trace_price.scrub_dnc_price
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Modal.Content>
            <CostBreakdownFooter />
        </Modal>
    );
}

function CostBreakdownFooter() {
    const { toggle } = useModal();
    return (
        <Modal.Footer>
            <Button
                fullWidth
                variant="primary"
                onClick={() => {
                    toggle();
                }}
            >
                Close{" "}
            </Button>
        </Modal.Footer>
    );
}

function getPerRecordLabel(price: number) {
    return `${price} ${pluralize("credit", price)} / record`;
}

function getCreditLabel(credits: number) {
    return `${formatNumber(credits)} ${pluralize("credit", credits)}`;
}
