import { useState } from "react";
import { useCurrentUser, type LandUser } from "./useUser";

import { INTERCOM_APP_ID } from "settings";

export const INTERCOM_UPDATE_INTERVAL = 1000 * 64;

export function loadIntercome(user: LandUser) {
    window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: INTERCOM_APP_ID,
        name: user.name,
        email: user.email,
    });
}

export function useIntercom() {
    const { getUser } = useCurrentUser();
    const [isIntercomLoaded, setIsIntercomLoaded] = useState(false);
    const load = async () => {
        const user = await getUser();
        if (user) {
            loadIntercome(user);
            setIsIntercomLoaded(true);
        }
    };

    const show = () => {
        if (!isIntercomLoaded) {
            load();
        }

        window.Intercom("show", {});
    };
    return {
        load,
        show,
    };
}
