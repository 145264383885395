const VIRDIS_COLORS = [
    "#440154",
    "#481567",
    "#482677",
    "#453781",
    "#404788",
    "#39568C",
    "#33638D",
    "#2D708E",
    "#287D8E",
    "#238A8D",
    "#1F968B",
    "#20A387",
    "#29AF7F",
    "#3CCB75",
    "#55C667",
    "#73D055",
    "#95D840",
    "#B8DE29",
    "#DCE319",
    "#FDE725",
];

function simple_gradient() {
    return VIRDIS_COLORS.join(",");
}

function calculate_color_ranges(data, invert) {
    let all_values = [];
    let total_value = 0;
    const range = [1000000, -1000000];

    for (const key in data) {
        if (data[key] == 0) {
            continue;
        }

        all_values.push(parseFloat(data[key]));
    }
    all_values = all_values.sort(function (a, b) {
        return a - b;
    });
    const trim_length = parseInt(all_values.length * 0.05);
    all_values = all_values.slice(trim_length, all_values.length - trim_length);

    for (const item of all_values) {
        total_value += item;

        if (range[0] > item) {
            range[0] = item;
        }
        if (range[1] < item) {
            range[1] = item;
        }
    }

    const mean = total_value / all_values.length;
    let total_variance = 0;
    for (const value of all_values) {
        total_variance += Math.pow(value - mean, 2);
    }
    const variance = total_variance / all_values.length;
    const sigma = Math.sqrt(variance, 2);

    const max_value = mean + 2 * sigma;
    let min_value = mean - 2 * sigma;

    if (min_value < 0) {
        min_value = 0;
    }
    if (max_value <= min_value) {
        // For limited sets where all values may be equal, shift the bottom down to highlight all entries
        min_value -= 10;
    }
    const diff = max_value - min_value;
    const step = diff / 19;

    const layer_color_interpolation = [
        "interpolate",
        ["linear"],
        ["feature-state", "value"],
    ];

    let count = 0;
    let colors = [...VIRDIS_COLORS];
    if (invert == true) {
        colors = colors.reverse();
    }
    for (const color of colors) {
        layer_color_interpolation.push(min_value + step * count);
        layer_color_interpolation.push(color);
        count += 1;
    }

    return layer_color_interpolation;
}

export { simple_gradient, calculate_color_ranges };
