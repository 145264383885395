/* eslint-disable react-hooks/exhaustive-deps */
import { keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatNumber } from "@src/functions";
import { usePagination } from "@src/hooks/usePagination";
import { Button } from "@src/land_ui/button/button";
import { Divider } from "@src/land_ui/Divider/Divider";
import { LoadingSpinner } from "@src/land_ui/loading/loading";
import { Sidebar, useSidebar } from "@src/land_ui/sidebar/sidebar";
import { Typography } from "@src/land_ui/typography/typography";
import { useExportsCalculatePriceCreate, useParcelsList } from "@src/orval/gen/api";
import {
    type FullParcel,
    type ParcelExportLandScrub,
    type ParcelExportSkipTrace,
    type ParcelsListParams,
} from "@src/orval/gen/model";
import { generateExportListName } from "@src/pages/parcel_viewer/controls/filter";
import { ExportListMenu } from "@src/pages/parcel_viewer/map_menu/export_list_menu";
import {
    type CountyOption,
    type ParcelExportPriceBreakdown,
} from "@src/pages/parcel_viewer/types";
import { ErrorMessageBox } from "@src/components/ErrorMessageBox";
import { ExportDetailCard } from "@src/components/parcel_detail/parcel_detail_card";
import { SummaryExportPriceDetails } from "./cost_breakdown";
import { type ExportsCalculatePriceRequest, PurchaseExport } from "./purchase_export";
import { SaveFilterModal } from "./save_filter_modal";
import { type FeaturePolygon } from "@src/components/map_tools/map_polygon";

interface MapFilterParcelProps {
    parcelParams: ParcelsListParams;
    setIsParcelList: (value: boolean) => void;
    countyOption: CountyOption;
    landScrubs: ParcelExportLandScrub;
    skipTrace: ParcelExportSkipTrace;
    polygons: FeaturePolygon[];
    resetFilterForm: () => void;
}
const PER_PAGE = 10;

export function MapFilterParcel({
    parcelParams,
    setIsParcelList,
    countyOption,
    polygons,
    landScrubs,
    skipTrace,
    resetFilterForm,
}: MapFilterParcelProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen } = useSidebar();
    const [isCreateFilterModalOpen, setIsCreateFilterOpen] = useState(false);
    const [activePurchaseExport, setActivePurchaseExport] = useState<string>(null);

    const {
        mutate,
        data: calculatePriceDetails,
        error,
    } = useExportsCalculatePriceCreate();

    const { data, isLoading: isLoadingParcels } = useParcelsList(
        {
            ...parcelParams,
            page: currentPage,
            page_size: PER_PAGE,
        },
        {
            query: {
                placeholderData: keepPreviousData,
            },
        },
    );

    const { dataList, triggerElement, isLoading } = usePagination<FullParcel>({
        currentPage,
        data: data?.results,
        totalCount: data?.count,
        root: ref.current,
        onNextPage: () => {
            setCurrentPage(currentPage + 1);
        },
    });

    const payloadRequest = useMemo<ExportsCalculatePriceRequest>(() => {
        const name = generateExportListName(
            Boolean(countyOption?.county)
                ? {
                      county: countyOption.county,
                      state: countyOption.state,
                      id: countyOption.id,
                      label: countyOption.label,
                  }
                : {
                      county: "Polygon",
                      state: "",
                      id: "",
                      label: "",
                  },
        );

        return {
            name,
            skip_trace: skipTrace,
            land_scrub: landScrubs,
            search_filters: parcelParams,
        };
    }, [countyOption, landScrubs, parcelParams, skipTrace, isOpen]);

    useEffect(() => {
        if (
            (countyOption?.county || polygons?.length > 0) &&
            Boolean(parcelParams) &&
            isOpen
        ) {
            mutate({
                // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                data: {
                    ...payloadRequest,
                },
            });
        }

        // This is a hack to prevent multiple request
        // We should remove this once we move to CalculatePrice to use GET instead of POST
    }, [JSON.stringify(payloadRequest)]);

    // If the purchase is successful, show the export list menu
    if (activePurchaseExport) {
        return (
            <ExportListMenu
                exportId={activePurchaseExport}
                onClose={() => {
                    setIsParcelList(false);
                    resetFilterForm();
                }}
            />
        );
    }

    return (
        <>
            <Sidebar.Header>
                <div className="lui-flex lui-gap-6">
                    <Button
                        variant="base"
                        icon="LeftArrow"
                        className="lui-flex lui-items-center"
                        onClick={() => {
                            setIsParcelList(false);
                        }}
                    />

                    <Typography size="xl" weight="medium">
                        {isLoadingParcels ? null : formatNumber(data?.count ?? 0)}{" "}
                        Parcels
                    </Typography>
                </div>
            </Sidebar.Header>

            <Sidebar.Content ref={ref}>
                {dataList.map((parcel, i) => (
                    <ExportDetailCard
                        key={`${i}-parcel-${parcel.APN}`}
                        parcel={parcel}
                        countyLabel={countyOption?.label ?? ""}
                    />
                ))}
                {triggerElement}
                {(isLoadingParcels || isLoading) && (
                    <div className="lui-py-5 lui-text-center">
                        <LoadingSpinner />
                    </div>
                )}
            </Sidebar.Content>

            <Sidebar.Footer fluid>
                <ErrorMessageBox isShown={Boolean(error)}>
                    Something wen't wrong while calculating the credits, please try
                    again.
                </ErrorMessageBox>

                <SummaryExportPriceDetails
                    details={
                        // TODO(API-TYPE-ISSUE): Fix the type in the calculate_price api
                        calculatePriceDetails as unknown as ParcelExportPriceBreakdown
                    }
                />

                <Divider horizontal />
                <div className="lui-flex lui-justify-between lui-gap-6 lui-p-6">
                    <SaveFilterModal
                        isOpen={isCreateFilterModalOpen}
                        setIsOpen={setIsCreateFilterOpen}
                        trigger={
                            <Button
                                variant="inline"
                                onClick={() => {
                                    setIsCreateFilterOpen(true);
                                }}
                            >
                                Save filter
                            </Button>
                        }
                        saveFilter={{
                            ...parcelParams,
                            polygons,
                            countyOption,
                        }}
                        initialFilterName={payloadRequest.name}
                    />

                    <PurchaseExport
                        details={
                            calculatePriceDetails as unknown as ParcelExportPriceBreakdown
                        }
                        payloadRequest={payloadRequest}
                        setActivePurchaseExport={setActivePurchaseExport}
                    />
                </div>
            </Sidebar.Footer>
        </>
    );
}
