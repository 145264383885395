const normalize = (str) => str.replace(/[^a-z0-9]/gi, "").toUpperCase();

export function detectHeaderMapping(headers) {
    const find = (terms) => {
        terms = terms.map(normalize);

        for (let j = 0; j < terms.length; j++) {
            for (let i = 0; i < headers.length; i++) {
                if (headers[i].includes(terms[j])) {
                    return i;
                }
            }
        }
        return -1;
    };

    headers = headers.map(normalize);

    // Define keywords from longest to shortest for best results
    return {
        apn: find(["APN - FORMATTED", "APN"]),
        first_name: find(["OWNER 1 FIRST NAME", "OWNER_1_FIRST", "FIRST NAME"]),
        last_name: find(["OWNER 1 LAST NAME", "OWNER_1_LAST", "LAST NAME"]),
        address: find(["OWNER_ADDRESS", "MAILING STREET ADDRESS", "MAIL ADDRESS"]),
        city: find(["OWNER_CITY", "MAIL CITY"]),
        state: find(["OWNER_STATE", "MAIL STATE"]),
        zip: find(["OWNER_ZIP", "MAIL ZIP", "ZIP"]),
        lat: find(["LATITUDE"]),
        lng: find(["LONGITUDE"]),
        size: find(["ACREAGE", "LOT SIZE"]),
    };
}
