import clsx from "clsx";
import { Typography } from "@src/land_ui/typography/typography";
import React, { forwardRef } from "react";

import "./Toggle.css";

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    labelClassName?: string;
    info?: string;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, disabled, labelClassName, info, ...props }: ToggleProps, ref) => {
        return (
            <div className="lui-flex lui-flex-col lui-flex-grow">
                <label
                    className={clsx(
                        "lui-inline-flex lui-flex-row lui-items-center lui-gap-2 lui-cursor-pointer lui-m-0 lui-p-0 lui-w-full lui-justify-between",
                        {
                            "lui-cursor-not-allowed": disabled,
                        },
                    )}
                >
                    {label && (
                        <Typography variant="span" className={labelClassName}>
                            {label}
                        </Typography>
                    )}
                    <span className={clsx(`lui-toggle`)}>
                        <input
                            ref={ref}
                            name={props.name}
                            type="checkbox"
                            disabled={disabled}
                            className="lui-toggle-input"
                            // prevent warning, it shows up when the toggle is disable
                            // Usually the props onChange should be used if you want to use this component
                            onChange={() => {}}
                            {...props}
                        />

                        <span className={clsx("lui-toggle-button", {})} />
                    </span>
                </label>
                {info && (
                    <Typography
                        size="sm"
                        variant="span"
                        color="gray-700"
                        className="lui-flex lui-gap-1.5 lui-items-center lui-mt-1.5"
                    >
                        {info}
                    </Typography>
                )}
            </div>
        );
    },
);
