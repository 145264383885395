import { type Dispatch, useReducer } from "react";
import { type ParcelExportReadOnly, type ParcelExportRow } from "@src/orval/gen/model";

type ViewType = "MAIN" | "FILTER" | "EXPORT" | "EXPORT_PARCELS_LIST";

export type ActionDispatch =
    | { type: "SET_VIEW"; view: ViewType }
    | { type: "SET_SELECTED_EXPORT"; export: ParcelExportReadOnly }
    | { type: "SET_EXPORT_PARCELS_LIST"; exportParcelList: ParcelExportRow[] };

export interface MenuSidebarProps {
    state?: MenuSidebar;
    dispatch?: Dispatch<ActionDispatch>;
}

function reducer(state: MenuSidebar, action: ActionDispatch) {
    switch (action.type) {
        case "SET_VIEW":
            return { ...state, currentView: action.view };
        case "SET_SELECTED_EXPORT":
            return { ...state, selectedExport: action.export };
        case "SET_EXPORT_PARCELS_LIST":
            return { ...state, exportParcelList: action.exportParcelList };
        default:
            return state;
    }
}

export interface MenuSidebar {
    currentView: ViewType;
    setCurrentView?: (view: ViewType) => void;
    selectedExport?: ParcelExportReadOnly;
    setSelectedExportId?: (id: string) => void;
    exportParcelList?: ParcelExportRow[];
}
export function useMenuSidebar() {
    return useReducer(reducer, { currentView: "MAIN", exportParcelList: [] });
}
