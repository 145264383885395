import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";

import { FilesDragAndDrop, RefillCreditsModal } from "components";
import {
    INSUFFICIENT_FUNDS,
    SKIP_TRACE_REPORT_TYPE_PREMIUM,
    SKIP_TRACE_REPORT_TYPE_STANDARD,
} from "constants";
import {
    detectHeaderMapping,
    formatCredits,
    formatNumber,
    parseErrorCode,
    parseErrorResponse,
    post,
    postData,
} from "functions";
import { Loading } from "library";

export default function SkipTraceCreate() {
    const navigate = useNavigate();

    // Current step in workflow
    const [step, setStep] = useState(0);

    // CSVFile model
    const [inputCSV, setInputCSV] = useState();

    // SkipTrace model
    const [skipTrace, setSkipTrace] = useState();

    // Header mapping names correspond to fields on SkipTrace model
    const [headerMapping, setHeaderMapping] = useState();

    // Field names correspond to header mapping column name
    const columnDefs = [
        { field: "first_name_column", headerName: "Owner First Name" },
        { field: "last_name_column", headerName: "Owner Last Name" },
        { field: "mailing_address_column", headerName: "Mailing Address" },
        { field: "mailing_city_column", headerName: "Mailing City" },
        { field: "mailing_state_column", headerName: "Mailing State" },
        { field: "mailing_zip_column", headerName: "Mailing Zip" },
    ];

    // Option names correspond to fields on SkipTrace model
    const [options, setOptions] = useState({
        scrub_duplicates: true,
        scrub_dnc: false,
        report_type: SKIP_TRACE_REPORT_TYPE_STANDARD,
    });

    const next = () => setStep(step + 1);
    const prev = () => setStep(step - 1);
    const done = () => navigate(`/home/skip_tracing/${skipTrace.id}`);

    switch (step) {
        case 0:
            return (
                <Upload
                    setInputCSV={setInputCSV}
                    setHeaderMapping={setHeaderMapping}
                    next={next}
                />
            );
        case 1:
            return (
                <HeaderMapping
                    inputCSV={inputCSV}
                    headerMapping={headerMapping}
                    setHeaderMapping={setHeaderMapping}
                    columnDefs={columnDefs}
                    prev={prev}
                    next={next}
                />
            );
        case 2:
            return (
                <Preview
                    inputCSV={inputCSV}
                    headerMapping={headerMapping}
                    columnDefs={columnDefs}
                    prev={prev}
                    next={next}
                />
            );
        case 3:
            return (
                <Options
                    inputCSV={inputCSV}
                    headerMapping={headerMapping}
                    options={options}
                    setOptions={setOptions}
                    skipTrace={skipTrace}
                    setSkipTrace={setSkipTrace}
                    prev={prev}
                    next={next}
                />
            );
        default:
            return (
                <Summary
                    skipTrace={skipTrace}
                    setSkipTrace={setSkipTrace}
                    prev={prev}
                    next={done}
                />
            );
    }
}

function Upload({ setInputCSV, setHeaderMapping, next }) {
    const [file, setFile] = useState();

    const onUpload = (files) => {
        setFile(files[0]);
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);

        try {
            const data = await postData("/api/csv_file/", formData, {
                // Disable jQuery contentType to generate correct multipart/form-data boundary
                contentType: false,
                // Prevent jQuery from parsing form data
                processData: false,
            });
            setInputCSV(data);

            const detectedHeaders = detectHeaderMapping(data.headers);
            const headerMapping = {
                first_name_column: detectedHeaders.first_name,
                last_name_column: detectedHeaders.last_name,
                mailing_address_column: detectedHeaders.address,
                mailing_city_column: detectedHeaders.city,
                mailing_state_column: detectedHeaders.state,
                mailing_zip_column: detectedHeaders.zip,
            };

            setHeaderMapping(headerMapping);
            next();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Upload CSV</h5>
            </div>
            <div className="card-body">
                <div className="mb-5 w-md-75 mx-auto">
                    <FilesDragAndDrop
                        onUpload={onUpload}
                        count={1}
                        formats={["csv"]}
                        containerStyles={{
                            width: "100%",
                            border: "2px dashed #cccccc",
                            padding: "20px",
                        }}
                        openDialogOnClick
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <p>Drag &amp; Drop</p>
                            <p>--- or ---</p>
                            <p>Click to Select File</p>
                        </div>
                    </FilesDragAndDrop>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={onSubmit}
                        disabled={!file}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

function HeaderMapping({
    inputCSV,
    headerMapping,
    setHeaderMapping,
    columnDefs,
    prev,
    next,
}) {
    const selectOptions = inputCSV.headers.map((v, i) => ({
        text: v,
        value: i,
    }));

    // Add blank option
    selectOptions.unshift({ value: -1, text: "" });

    const onChange = (e) => {
        setHeaderMapping({
            ...headerMapping,
            [e.target.name]: parseInt(e.target.value, 10),
        });
    };

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Column Header Mapping</h5>
            </div>
            <div className="card-body">
                <div className="mb-4 w-lg-50 mx-auto">
                    {columnDefs.map(({ field, headerName }) => (
                        <Select
                            key={field}
                            name={field}
                            label={headerName}
                            options={selectOptions}
                            selectedValue={headerMapping[field]}
                            onChange={onChange}
                        />
                    ))}
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={prev}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={next}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

function Select({ name, label, options, selectedValue, onChange }) {
    const fieldID = `select_${name}`;
    return (
        <div className="form-group">
            <label htmlFor={fieldID} className="p text-bold">
                {label}:
            </label>
            <select
                id={fieldID}
                className="form-control"
                name={name}
                onChange={onChange}
                value={selectedValue}
            >
                {options.map(({ text, value }) => (
                    <option key={value} value={value}>
                        {text}
                    </option>
                ))}
            </select>
        </div>
    );
}

function Preview({ inputCSV, headerMapping, columnDefs, prev, next }) {
    // Convert inputCSV rows to objects for AgGrid using header mapping
    const rows = inputCSV.preview.map((listRow) => {
        const objRow = {};
        for (const field in headerMapping) {
            objRow[field] = listRow[headerMapping[field]];
        }
        return objRow;
    });

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Preview</h5>
            </div>
            <div className="card-body">
                <div className="mb-4">
                    <div className="ag-theme-material" style={{ height: "500px" }}>
                        <AgGridReact columnDefs={columnDefs} rowData={rows} />
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={prev}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={next}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

function Options({
    inputCSV,
    headerMapping,
    options,
    setOptions,
    skipTrace,
    setSkipTrace,
    next,
    prev,
}) {
    const [loading, setLoading] = useState(false);

    // Create or update Skip Trace
    const saveSkip = async () => {
        setLoading(true);

        let url = "/api/skiptrace/";
        if (skipTrace) {
            url = `/api/skiptrace/${skipTrace.id}`;
        }

        const data = {
            input_csv: inputCSV.id,
            ...headerMapping,
            ...options,
        };

        try {
            const skipTrace = await post(url, data);
            setSkipTrace(skipTrace);
            next();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
        setLoading(false);
    };

    if (loading) {
        return <Loading />;
    }

    const isPremium = options.report_type === SKIP_TRACE_REPORT_TYPE_PREMIUM;

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Options</h5>
            </div>
            <div className="card-body">
                <div className="mb-4 w-lg-50 mx-auto">
                    <Checkbox
                        label="Skip Trace?"
                        helpText={`${formatCredits(5)} / row`}
                        tooltip="Includes up to 6 phone numbers and the type of each phone number (mobile, landline, VOIP)."
                        name="skip_trace"
                        value={true}
                        disabled
                    />
                    <Checkbox
                        label="Scrub Duplicates?"
                        helpText="Free / row"
                        tooltip="Removes duplicate owners prior to skip tracing. You will only be charged for unique rows."
                        name="scrub_duplicates"
                        value={options.scrub_duplicates}
                        onChange={(e) =>
                            setOptions({
                                ...options,
                                scrub_duplicates: e.target.checked,
                            })
                        }
                    />
                    <Checkbox
                        label="Premium Results"
                        helpText={`${formatCredits(1)} / row`}
                        tooltip="Includes up to 7 phone numbers, the type of each phone number (mobile, landline, VOIP), age of owner, owner email, relative phone numbers and emails, and higher hit rate (usually > 95%)."
                        name="premium"
                        value={isPremium}
                        onChange={(e) =>
                            setOptions({
                                ...options,
                                report_type: e.target.checked
                                    ? SKIP_TRACE_REPORT_TYPE_PREMIUM
                                    : SKIP_TRACE_REPORT_TYPE_STANDARD,
                            })
                        }
                    />
                    {isPremium ? (
                        <Checkbox
                            label="Scrub DNC?"
                            helpText={`${formatCredits(1)} / row`}
                            tooltip="Removes all known Do Not Call and Litigator phone numbers."
                            name="scrub_dnc"
                            value={options.scrub_dnc}
                            onChange={(e) =>
                                setOptions({
                                    ...options,
                                    scrub_dnc: e.target.checked,
                                })
                            }
                        />
                    ) : null}
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={prev}
                        disabled={loading}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={saveSkip}
                        disabled={loading}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: theme.typography.pxToRem(12),
    },
}));

function Checkbox({ label, helpText, tooltip, name, value, onChange, disabled }) {
    const fieldID = `checkbox_${name}`;
    return (
        <div className="row mb-2">
            <div className="col-6 text-end">
                <label className="form-check-label" htmlFor={fieldID}>
                    <h6 className={disabled && "text-muted"}>
                        {label}
                        {tooltip ? (
                            <BootstrapTooltip title={tooltip}>
                                {" "}
                                <i className="fas fa-info-circle"></i>
                            </BootstrapTooltip>
                        ) : null}
                    </h6>
                </label>
            </div>
            <div className="col-6">
                <div className="form-check form-switch">
                    <input
                        id={fieldID}
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={String(value)}
                        checked={value}
                        onChange={onChange}
                        disabled={Boolean(disabled)}
                    />
                    <span className="ms-2">{helpText}</span>
                </div>
            </div>
        </div>
    );
}

function Summary({ skipTrace, next, prev }) {
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState();

    const start = async () => {
        setLoading(true);
        setErrorCode(null);
        try {
            await post(`/api/skiptrace/${skipTrace.id}/start/`);
            next();
        } catch (xhr) {
            const code = parseErrorCode(xhr);
            if (code !== INSUFFICIENT_FUNDS) {
                toast.error(parseErrorResponse(xhr));
            }
            setErrorCode(code);
        }
        setLoading(false);
    };

    const lineItems = [
        {
            text: "Total Number of Rows",
            value: formatNumber(skipTrace.tasks_count),
        },
        { text: "Scrub Duplicates", value: "Free" },
        {
            text: "Skip Your List",
            value: formatCredits(skipTrace.price_base + skipTrace.price_scrub_dnc),
        },
        {
            text: "Total Skip Cost",
            value: formatCredits(skipTrace.price_total),
            style: {
                backgroundColor: "#e3fae3",
            },
            className: "row mb-2 p-2",
        },
    ];

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Skip Tracing Summary</h5>
            </div>
            <div className="card-body">
                {errorCode === INSUFFICIENT_FUNDS ? (
                    <RefillCreditsModal
                        amount={skipTrace.price_total}
                        onConfirmed={start}
                        onDismissed={() => setErrorCode(null)}
                    />
                ) : null}
                <div className="mb-4 w-lg-50 mx-auto">
                    {lineItems.map((props) => (
                        <LineItem key={props.text} {...props} />
                    ))}
                </div>
                <div className="d-flex justify-content-center gap-2 mb-2">
                    <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={prev}
                        disabled={loading}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        className="btn bg-gradient-success"
                        onClick={start}
                        disabled={loading}
                    >
                        Start Skipping
                    </button>
                </div>
            </div>
        </div>
    );
}

function LineItem({ text, value, className, style }) {
    return (
        <div key={text} className={className || "row mb-2"} style={style}>
            <div className="col-6 text-bold text-end">{text}:</div>
            <div className="col-6 text-nowrap">{value}</div>
        </div>
    );
}
