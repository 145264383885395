import type { Location } from "react-router-dom";

export const getKeyFromHash = (location: Location, key: string) => {
    const params = location.hash?.substring(1).split("&");
    for (const param of params) {
        const [k, v] = param.split("=");
        if (k === key) {
            return v;
        }
    }
};

export const setKeyInHash = (location: Location, key: string, value: string) => {
    const params = location.hash?.substring(1).split("&");
    const newParams = [];
    let found = false;
    for (const param of params) {
        const [k] = param.split("=");
        if (k === key) {
            newParams.push(`${k}=${value}`);
            found = true;
        } else {
            newParams.push(param);
        }
    }
    if (!found) {
        newParams.push(`${key}=${value}`);
    }
    return `#${newParams.join("&")}`;
};

export function formatCurrency(value: number) {
    if (value === null || value === undefined) {
        return PLACEHOLDER_LABEL;
    }

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);
}

export const PLACEHOLDER_LABEL = "——";
