import { useCallback } from "react";

import { Button } from "@src/land_ui/button/button";
import { Icon } from "@src/land_ui/icon/icon";
import { Typography } from "@src/land_ui/typography/typography";
import { ControlPopover } from "./control_popover";
import { useMapRuler } from "./land_ruler";

export function MapToolsControl() {
    const { activateRuler, deactivateRuler, LandRuler } = useMapRuler();
    const onRulerClick = useCallback(() => {
        if (LandRuler.isActive) {
            deactivateRuler();
        } else {
            activateRuler();
        }
    }, [LandRuler.isActive, activateRuler, deactivateRuler]);

    return (
        <ControlPopover
            icon="Tool"
            tooltipContent="Tools"
            title="Tools"
            placement="left"
            dismissOnClick
        >
            <div className="lui-flex lui-justify-between lui-items-center  hover:lui-bg-gray-100 ">
                <Button
                    variant="base"
                    fullWidth
                    onClick={onRulerClick}
                    className="lui-px-4 lui-py-2"
                >
                    <Typography
                        variant="span"
                        weight="medium"
                        className="lui-flex lui-gap-2"
                    >
                        <Icon name="Ruler" size="md" />
                        Ruler
                    </Typography>
                </Button>
            </div>
        </ControlPopover>
    );
}
